
import { Container, Navbar, Modal, Form, Button, Row, Col ,Table ,ButtonGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useState ,useEffect } from 'react';
import Axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from '../../config.json';
import SideMenuLayout from './Hamburger';
import Dashboard from '../../Admin/Pages/Dashboard';
import { useUserContext } from './Context';
// import { NavLink ,Link  } from 'react-router-dom';
import './Navbar.css'
function NavbarTop() {
    const { points } = useUserContext();

    const [SteamId, setSteamId] = useState("");
    const [Password, setPassword] = useState("");
    const [RegSteam, regisSteamId] = useState("");
    const [RegPass, regisPassword] = useState("");
    const [RegName, regisName] = useState("");
    const [RegPlayid, regisPlayerID] = useState("");
    const [RegEmail, regisEmail] = useState("");
    const [GotPassword, setGotPassword] = useState("");
    const [isDuplicateSteamID, setIsDuplicateSteamID] = useState(false);
    const [isDuplicateSteamID2, setIsDuplicateSteamID2] = useState(false);
    const [isDuplicateName, setIsDuplicateName] = useState(false);
    const [isDuplicatePlayid, setIsDuplicatePlayid] = useState(false);
    const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
    const [Datauser, setDatauser] = useState([]);
    
    
    const [isValidSteamID, setIsValidSteamID] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isValidName, setIsValidName] = useState(true);
    const [isValidPlayID, setIsValidPlayID] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const MySwal = withReactContent(Swal);
    const API_URL = config.APP_API_URL;
    const URL_DISCORD = config.APP_URL_DISCORD;
    const [ users , setUserstop10] = useState([])
   
    const clearForm = () => {
      setSteamId('');
      setPassword('');
      regisSteamId('');
      regisPassword('');
      regisName('');
      regisPlayerID('');
      regisEmail('');
      setIsDuplicateSteamID(false);
      setIsDuplicateSteamID2(false);
      setIsDuplicateName(false);
      setIsDuplicatePlayid(false);
      setIsDuplicateEmail(false);
      setIsValidSteamID(true);
      setIsPasswordValid(true);
      setIsValidName(true);
      setIsValidPlayID(true);
      setIsValidEmail(true);
    }


    
    const Toast = MySwal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', MySwal.stopTimer)
        toast.addEventListener('mouseleave', MySwal.resumeTimer)
      }
    })

    useEffect(() => {
      const tokens = localStorage.getItem("Token");
      const apiKey = config.apiKey;
      const encodedApiKey = btoa(apiKey);  
      const headers = {
        "Content-Type": 'application/json',
        "Authoriztion": `${tokens}`,
        "X-API-Key": encodedApiKey,
        "X-Client-Domain": window.location.origin
      }
    
      const fetchLoginToken = async () => {
        if (tokens !== null) {
          try {
            const response = await Axios.post(`${API_URL}/api/login/authen`, {}, { headers });
    
            if (response.data.status === 'ok') {
              setIsLoggedIn(true);
              setDatauser(response.data.users[0]);
              const Login = localStorage.getItem("Login");
              if (Login == null) {
                
                const handleToast = () => {
                  Toast.fire({
                    icon: 'success',
                    title: <i>{response.data.message}</i>,
                  }).then(() => {
                    if(response.data.users[0].userlevel === 'A'){
                      // <Dashboard Data={response.data.users[0]} />
                      window.location.href = '/Admin/Home';
                    }else{
                      window.location.href = '/Home';
                    }
                    
                  });
                }
    
                handleToast();
                localStorage.setItem("Login", response.data.message);
              }
            } else {
                if(response.data.status === 'ban'){
                  Toast.fire({
                    icon: 'error',
                    timer: 5000,
                    title: <i>{response.data.message}</i>
                  }).then(() => {
                    localStorage.removeItem('Token');
                    localStorage.removeItem('Login');
                    setIsLoggedIn(false);
                    window.location.href = '/';
                  });
                }else{
                  Toast.fire({
                    icon: 'error',
                    title: <i>{response.data.message}</i>
                  }).then(() => {
                    localStorage.removeItem('Token');
                    localStorage.removeItem('Login');
                    setIsLoggedIn(false);
                    window.location.href = '/';
                  });
                }

              


            }
          } catch (error) {
            // จัดการข้อผิดพลาด
          }
        } else {
          // window.location.href = '/homepage';
        }
      };
    
      fetchLoginToken();
    
      return () => {
        // console.log('Component destroyed');
      };
    }, []);
    

    const handleLogoutClick = () => {
      MySwal.fire({
        title: 'จะออกจากระบบใช่หรือไม่',
        text: "Will you log out or not?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.isConfirmed) {
          Toast.fire({
            icon: 'warning',
            title: <i>กำลังออกจากระบบ</i>
          }).then(() => {
            localStorage.removeItem('Token');
            localStorage.removeItem('Login');
            localStorage.removeItem('isPopupClosed');
            setIsLoggedIn(false);
            // window.location.reload();
            window.location.href = '/';
            })
  
   
        }
      })
   
    }

    const LoginMember = (e) => {
      e.preventDefault();
      if (SteamId === "" || Password === "") {
        MySwal.fire({
          title: <strong>จำเป็นต้องใส่ข้อมูลให้ครบ</strong>,
          html: <i>Complete information is required.</i>,
          icon: 'error'
        })
        return;
      }
      if (SteamId !== "" && Password !== "") {
        Axios.post(API_URL+"/api/login/member", {
          steamid: SteamId,
          password: Password,
        }).then((response) => {
          if(response.data.status === 'ok'){
            localStorage.setItem("Token", response.data.token)

            Toast.fire({
              icon: 'success',
              title: <i>{response.data.message}</i>
            }).then(() => {
              clearForm();
              setLogin(false);
              window.location.reload();
            })

          }else{
                MySwal.fire({
                title: <strong>ID/Password ไม่ถูกต้องโปรดลองใหม่</strong>,
                html: <i>Invalid ID/Password, please try again</i>,
                icon: 'error'
              })
          }
        })
      }
    
    };
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const error = urlParams.get('error');

      if (token) {
        localStorage.setItem('Token', token);
        Toast.fire({
          icon: 'success',
          title: <i>กำลังเข้าสู่ระบบ</i>
        }).then(() => {
          clearForm();
          urlParams.delete('token');
          window.location.replace(window.location.pathname + urlParams.toString());
        });
      } else if (error) {
        Toast.fire({
          icon: 'error',
          title: <i>{error}</i>
        }).then(() => {
          clearForm();
          urlParams.delete('error');
          window.location.replace(window.location.pathname + urlParams.toString());
        });
      }

    }, []);


      const getTop10 = () => {
        Axios.get(API_URL+"/api/top10").then((response) => {
            setUserstop10(response.data);
          }).catch((error) => {
            MySwal.fire({
              title: <strong>ไม่สามารถดึงข้อมูลได้</strong>,
              html: <i>Failed to fetch!</i>,
              icon: 'error'
            })
          });
      };

      const LoginDiscord = () => {
        MySwal.fire({
          title: 'จะล๊อกอินด้วย Discord ใช่ไหม',
          text: 'Are you going to log in with Discord?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!',
        }).then((result) => {
          if (result.isConfirmed) {
            const CLIENT_ID = config.DISCORD_CLIENT_ID;
            const REDIRECT_URI = config.APP_API_URL;
            window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}/auth/discord/callback&response_type=code&scope=identify`;
         
            const handleDiscordLoginCallback = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            try {
              const response = await Axios.post('/auth/discord/callback', { code });
              // console.log("รอข้อมูล");
              console.log(response.data);
            } catch (error) {
              // console.log("ไม่มี");
              console.error(error);
            }
          };
          if (window.location.pathname === '/auth/discord/callback') {
            handleDiscordLoginCallback();
          }
           }
        });
      };
      
      const LoginSteam =  () => {
        MySwal.fire({
          title: 'จะล๊อกอินด้วย Steam ใช่ไหม',
          text: "Are you going to log in with Steam?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then((result) => {
          if (result.isConfirmed) {
            const handleSteamLogin = async () => {
              try {
                window.location.href = `${API_URL}/api/auth/steam`;
               
              } catch (error) {
                console.error('เกิดข้อผิดพลาดในการล็อกอินด้วย Steam', error);
              }
            };
            
            handleSteamLogin();
            
          }
        })
  };
  

  const Sentmail = (e) => {
    e.preventDefault();
    if (GotPassword === "") {
      MySwal.fire({
        title: <strong>จำเป็นต้องใส่ Email ที่ลงทะเบียนไว้</strong>,
        html: <i>You must enter your registered email address.</i>,
        icon: 'error'
      })
      return;
    }
    if (GotPassword !== "") {
      Axios.post(API_URL+"/api/member/sentmail", {
        email: GotPassword,
      }).then((response) => {
        if(response.data.status === 'ok'){
          // localStorage.setItem("Token", response.data.token)

          Toast.fire({
            icon: 'success',
            title: <i>{response.data.message}</i>
          }).then(() => {
            clearForm();
            // setLogin(false);
            // window.location.reload();
          })

        }
        else{
          Toast.fire({
            icon: 'error',
            title: <i>{response.data.message}</i>
          })
        }
      })
    }
  
  };

  const handleSteamIDChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*$/; 

    if (regex.test(value) || value === '') {
      regisSteamId(value);
      setIsValidSteamID(true);
    } else {
      setIsValidSteamID(false);
    }
  };
  const handleNameChange = (event) => {
    const value = event.target.value;
    const regex = /^.*$/;
    if (regex.test(value) || value === '') {
      regisName(value);
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }
  };
  const handlePlayIDChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*$/; 
    if (regex.test(value) || value === '') {
      regisPlayerID(value);
      setIsValidPlayID(true);
    } else {
      setIsValidPlayID(false);
    }
  };
   
  const handleEmailChange = (event) => {
    const value = event.target.value;
    const regex = /^.*$/;
    if (regex.test(value) || value === '') {
  
         regisEmail(value);
      setIsValidEmail(true);

    } else {
      setIsValidEmail(false);
    }
  };
  
  


  const checkDuplicateSteamID = async () => {
    try {
      const response = await Axios.get(`${API_URL}/api/check/SteamID/${RegSteam}`);
      const isDuplicate = response.data.checkSteamID; // ข้อมูลที่ได้จากเซิร์ฟเวอร์
      const isDuplicate2 = response.data.checkSteamID2;
      setIsDuplicateSteamID(isDuplicate);
      setIsDuplicateSteamID2(isDuplicate2);
    } catch (error) {
      console.error('Error checking duplicate SteamID/EpicID:', error);
    }
  };
  const checkDuplicateName = async () => {
    try {
      const response = await Axios.get(`${API_URL}/api/check/Name/${RegName}`);
      const isDuplicatename = response.data.checkname; // ข้อมูลที่ได้จากเซิร์ฟเวอร์
      setIsDuplicateName(isDuplicatename);
    } catch (error) {
      console.error('Error checking duplicate Name:', error);
    }
  };
  const checkDuplicatePlayerID = async () => {
    try {
      const response = await Axios.get(`${API_URL}/api/check/PlayerID/${RegSteam}/${RegPlayid}`);
      const isDuplicateplayerid = response.data.checkplayerid; // ข้อมูลที่ได้จากเซิร์ฟเวอร์
      setIsDuplicatePlayid(isDuplicateplayerid);
    } catch (error) {
      console.error('Error checking duplicate playerid:', error);
    }
  };

  const checkDuplicateEmail = async () => {
    try {
      const response = await Axios.get(`${API_URL}/api/check/Email/${RegEmail}`);
      const isDuplicateEmail = response.data.checkemail; // ข้อมูลที่ได้จากเซิร์ฟเวอร์
      setIsDuplicateEmail(isDuplicateEmail);
    } catch (error) {
      console.error('Error checking duplicate Email:', error);
    }
  };


  useEffect(() => {
    if (RegSteam !== '') {
      checkDuplicateSteamID();
    }
  }, [RegSteam]);
  
  useEffect(() => {
    if (RegName !== '') {
      checkDuplicateName();
    }
  }, [RegName]);
  
  useEffect(() => {
    if (RegPlayid !== '' && RegSteam !=='') {
      checkDuplicatePlayerID();
    }
  }, [RegPlayid, RegSteam]);
  
  useEffect(() => {
    if (RegEmail !== '') {
      checkDuplicateEmail();
    }
  }, [RegEmail]);

  const handlePasswordChange = (value) => {
    regisPassword(value);
    const regex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (regex.test(value)) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const Register = async (e) => {
    e.preventDefault();
    if (isDuplicateSteamID) {
      MySwal.fire({
        title: <strong>SteamID/EpicID ไม่มีในฐานข้อมูล</strong>,
        html: <i>SteamID/EpicID Not in the database</i>,
        icon: 'error'
      })
      return;
    } 
    if (isDuplicateSteamID2) {
      MySwal.fire({
        title: <strong>SteamID/EpicID เป็นสมาชิกแล้ว</strong>,
        html: <i>SteamID/EpicID Already a member</i>,
        icon: 'error'
      })
      return;
    } 

    if (!isPasswordValid) {
      MySwal.fire({
        title: <strong>Password ต้องมีความยาวอย่างน้อย 8 ตัวอักษร ตัวเล็ก-ตัวใหญ่อย่างน้อย 1 ตัว a-Z</strong>,
        html: <i>Password must be at least 8 characters long, at least 1 lower-case letter a-Z.</i>,
        icon: 'error'
      })
      return;
    } 

    if (isDuplicateName) {
      MySwal.fire({
        title: <strong>มีคนใช้ชื่อนี้แล้ว</strong>,
        html: <i>This name is already taken</i>,
        icon: 'error'
      })
      return;
    } 
    if (!isValidPlayID) {
      MySwal.fire({
        title: <strong>PlayerID เป็นตัวเลขเท่านั้น</strong>,
        html: <i>PlayerID only numbersr</i>,
        icon: 'error'
      })
      return;
    } 
    if (isDuplicatePlayid) {
      MySwal.fire({
        title: <strong>ไม่พบไอดีผู้เล่นออนไลน์</strong>,
        html: <i>Online player ID not found</i>,
        icon: 'error'
      })
      return;
    } 
    if (isDuplicateEmail) {
      MySwal.fire({
        title: <strong>มี Email นี้ในระบบแล้ว</strong>,
        html: <i>This email already exists in the system.</i>,
        icon: 'error'
      })
      return;
    } 
    if (RegSteam === '' || RegPass === '' || RegName === '' || RegPlayid === '' || RegEmail === '') {
      MySwal.fire({
        title: <strong>กรุณาใส่ข้อมูลให้ครบทุกช่อง</strong>,
        html: <i>Please complete all fields.</i>,
        icon: 'error'
      })
      return;
    } else{
      Axios.post(API_URL+"/api/create/member", {
        Steam: RegSteam,
        Pass: RegPass,
        Name: RegName,
        Playid: RegPlayid,
        Email: RegEmail,
      }).then((response) => {
        if(response.data.status === 'ok'){
  
          Toast.fire({
            icon: 'success',
            title: <i>{response.data.message}</i>
          })
         .then(() => {
          BackLogin();
          })
        } else {
          MySwal.fire({
            html: <i>{response.data.message}</i>,
            icon: 'error'
          })
        }
      })
    }

}


    const [show, setShow] = useState(false);
    const [login, setLogin] = useState(false);
    const [register, setRegister] = useState(false);
    const [gotpass, setGotpass] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const handleClose = () => {setShow(false);clearForm();}
    const loginClose = () => {setLogin(false);clearForm();}
    const registerClose = () => {setRegister(false);clearForm();}
    const gotpassClose = () => {setGotpass(false);clearForm();}
    const handleShow = () => {
        setShow(true);
        getTop10();
    }
    const loginShow = () => {
      setLogin(true);
  }

const RegisterMember =() => {
  
  setLogin(false);
  setRegister(true);
}
const forgotpassword =(e) => {
  setShowForm(prevState => !prevState);
}
const BackLogin =() => {
  setLogin(true);
  setRegister(false);
  clearForm()
}



    return (
      <>
     
        <Navbar bg="dark" variant="dark" fixed="top">
          <Container fluid>
          {isLoggedIn ? (
  <>

<SideMenuLayout handleLogoutClick={handleLogoutClick} Datauser={Datauser} Nowpoints={points} />



  
    {/* <NavLink to="/" exact activeClassName="active">Home</NavLink>
    <NavLink to="/homepage" activeClassName="active">Homepage</NavLink>
    <NavLink to="/contact/ddd" activeClassName="active">Contact</NavLink> */}


    {/* <Navbar.Collapse className="justify-content-end">
          <Navbq  ar.Text>
          </Navbq>
          <Figure>
      <Figure.Image
        width={32}
        height={32}
        alt="IMG"
        // src="https://via.placeholder.com/171x180"
      />
    </Figure>
    <Button variant="outline-danger" className="rounded-circle" onClick={handleLogoutClick}>
      <i className="bi bi-box-arrow-left"></i>
    </Button>
        </Navbar.Collapse> */}


  </>
) : (
  <>
<Navbar.Brand href={URL_DISCORD} target="_blank">
      <i class="bi bi-discord me-2"></i>
      Discord[FN]
    </Navbar.Brand>
    <Button className="btn btn-danger me-3" onClick={(e) => handleShow()}>TOP10</Button>
    <Button className="d-flex" onClick={loginShow} variant="outline-success">
      Login
    </Button>
  </>
)}

          
                


                <Modal
                    show={login}
                    onHide={loginClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
        <Form>
          <Modal.Header closeButton>
          <Modal.Title> LOGIN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">
                     <i class="bi bi-person-circle"></i> ID
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control  type="text"  onChange={(event) => {setSteamId(event.target.value) }} placeholder="SteamID/EpicID/Name" required/>
                    </Col>
                  </Form.Group>
                   
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="2">
                    <i class="bi bi-code"></i> Pass
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control type="password" onChange={(event) => {setPassword(event.target.value) }} placeholder="Password" required/>
                    </Col>
                  </Form.Group>
                  <Form.Label column sm="2">
                   Login with
                    </Form.Label>
                  <Button variant="primary" onClick={LoginDiscord} className="rounded-circle me-2"><i class="bi bi-discord"></i></Button>
                  <Button variant="dark" onClick={LoginSteam} className="rounded-circle me-2"><i class="bi bi-steam"></i></Button>
                  <Button variant="success" type='submit' className="float-end" onClick={LoginMember}><i class="bi bi-box-arrow-in-right"></i> LOGIN</Button>
      

                  <div style={{ display: showForm ? 'block' : 'none' }}>
         <br />
          <Form>
          <Form.Group as={Row} className="mb-3">
          <Col sm="12">
            <div className="d-flex">
              <Form.Control type="email" placeholder="Email" onChange={(event) => {setGotPassword(event.target.value) }} required />
              <Button variant="success" className="ms-2" onClick={Sentmail}>Sent</Button>
            </div>
          </Col>
        </Form.Group>
          </Form>
        </div>      

                
        </Modal.Body>
        <Modal.Footer>
          
                  <ButtonGroup size="sm" className="mx-auto my-6">
            {/* <Button variant="warning" onClick={RegisterMember}>Register</Button> */}
            {/* <Button variant="danger" onClick={forgotpassword}>{showForm ? 'Close Form' : 'Forgot Password'}</Button> */}

          </ButtonGroup>

                  </Modal.Footer></Form>
      </Modal>
      




      <Modal 
                    show={register}
                    onHide={registerClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size="xl"
                >
        <Modal.Header closeButton>
          <Modal.Title> Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container className="p-0">
        <Row>
        <Col className="p-4">
                <Form>

                <Form.Group as={Row} className="mb-3" controlId="formSteamId">
                <Form.Label column sm="3">
                  <i className="bi bi-person-circle"></i> SteamID
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    value={RegSteam}
                    onChange={handleSteamIDChange}
                    placeholder="SteamID/EpicID"
                    required
                    isInvalid={!isValidSteamID || isDuplicateSteamID || isDuplicateSteamID2}
                    isValid={isValidSteamID && RegSteam !== '' && !isDuplicateSteamID && !isDuplicateSteamID2}
                  />
                    <Form.Control.Feedback type="invalid">
                      {!isValidSteamID && 'เป็นตัวเลขเท่านั้น'}
                      {isDuplicateSteamID && 'SteamID/EpicID ไม่มีในฐานข้อมูล.'}
                      {isDuplicateSteamID2 && 'SteamID/EpicID เป็นสมาชิกแล้ว.'}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      {!isDuplicateSteamID && !isDuplicateSteamID2 && 'SteamID/EpicID ใช้งานได้ยังไม่เป็นสมาชิก'}
                    </Form.Control.Feedback>
                </Col>
              </Form.Group>


                   
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
              <Form.Label column sm="3">
                <i className="bi bi-key-fill"></i> Pass
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="password"
                  onChange={(event) => handlePasswordChange(event.target.value)}
                  placeholder="Password"
                  required
                  isInvalid={!isPasswordValid}
                  isValid={isPasswordValid && RegPass !== ''}
                />
                <Form.Control.Feedback type="invalid">
                  {!isPasswordValid && 'ต้องมีความยาวอย่างน้อย 8 ตัวอักษร ตัวเล็ก-ตัวใหญ่อย่างน้อย 1 ตัว a-Z'}
               
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                      {isPasswordValid && 'สามารถใช้รหัสผ่านนี้ได้'}
                    </Form.Control.Feedback>
              </Col>
            </Form.Group>

    
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                    <i class="bi bi-braces-asterisk"></i> Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control 
                      type="text" 
                      value={RegName}
                      onChange={handleNameChange} 
                      placeholder="Name" required
                      isInvalid={!isValidName || isDuplicateName}
                      isValid={isValidName && RegName !== '' && !isDuplicateName}
                      />
                       <Form.Control.Feedback type="invalid">
                      {isDuplicateName && 'มีคนใช้ชื่อนี้แล้ว.'}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      {!isDuplicateName && 'สามารถใช้ชื่อนี้ได้'}
                    </Form.Control.Feedback>
                    </Col>  
                  </Form.Group>

                  {/* RegPlayid regisPlayerID isValidPlayID setIsValidPlayID isDuplicatePlayid setIsDuplicatePlayid */}
                
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                    <i class="bi bi-fingerprint"></i> PlayerID
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control 
                      type="text" 
                      value={RegPlayid}
                      onChange={handlePlayIDChange}
                      placeholder="PlayerID" 
                      required
                      isInvalid={!isValidPlayID || isDuplicatePlayid}
                      isValid={isValidPlayID && RegPlayid !== '' && !isDuplicatePlayid}
                      />
                      <Form.Control.Feedback type="invalid">
                      {!isValidPlayID && 'เป็นตัวเลขเท่านั้น'}
                      {isDuplicatePlayid && 'ไม่พบไอดีผู้เล่นออนไลน์.'}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      {!isDuplicatePlayid && 'พบผู้เล่นไอดีนี้ออนไลน์'}
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">
                    <i class="bi bi-envelope-at-fill"></i> Email
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control 
                      type="email" 
                      value={RegEmail}
                      onChange={handleEmailChange}
                      placeholder="Email" 
                      required
                      isInvalid={!isValidEmail || isDuplicateEmail}
                      isValid={isValidEmail && RegEmail !== '' && !isDuplicateEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                      {!isValidEmail && 'รูปแบบ Email เท่านั้น'}
                      {isDuplicateEmail && 'มี Email นี้ในระบบแล้ว.'}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      {!isDuplicateEmail && 'สามารถใช้ Email นี้ได้'}
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
            
                  {/* <ButtonGroup size="sm" className="mx-auto my-6">
                    <Button variant="warning"  onClick={BackLogin}>Back to login</Button>
                  </ButtonGroup> */}
                  {/* <Button variant="primary" onClick={LoginDiscord} className="rounded-circle me-2"><i class="bi bi-discord"></i></Button> */}
                  {/* <Button variant="dark" onClick={LoginSteam} className="rounded-circle me-2"><i class="bi bi-steam"></i></Button> */}
                  {/* <Button variant="success" type='submit' className="float-end" onClick={Register}><i class="bi bi-box-arrow-in-right"></i> Register</Button> */}
      

                  

                </Form>
                <ButtonGroup size="sm" className="mx-auto my-1">
    <Button variant="warning" onClick={BackLogin}>Back to login</Button>
  </ButtonGroup>
  <Button variant="success" type='submit' className="float-end" onClick={Register}>
    <i className="bi bi-box-arrow-in-right"></i> Register
  </Button>
                </Col>
                <Col className="text-center">
                  <div className="howtoget-container">
                  <h4 className="fs-4 mt-3">
                    How to get SteamID&EpicID And PlayerID
                  </h4>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/-8RPJ7kL6aw?start=541" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                 Or <a href='https://discord.com/channels/626846835331301391/932301329395773521/991362492573945928' target="_blank" rel="noopener noreferrer">LinkDiscord</a>

                </Col>




                </Row>
                </Container>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-start">

</Modal.Footer> */}


      </Modal>
         


      <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
        <Modal.Header closeButton>
          <Modal.Title> ONLINE TOP10</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover variant="dark">
        <thead>
            <tr align ="center">
            <th style={{ width: '10%' }}>No.</th>
            <th style={{ width: '50%' }}>Name</th>
            <th style={{ width: '20%' }}>Time online</th>
            </tr>
        </thead>
        <tbody>
            {users.map((user, index) => (
            <tr key={user.Name}>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td style={{ textAlign: 'left' }}>{user.Name +' '+` [${user.TribeName}]`}</td>
                <td style={{ textAlign: 'center' }}>{user.MinutesPlayed}</td>
            </tr>
            ))}
        </tbody>
        </Table>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>







            <Navbar.Toggle aria-controls="navbarScroll" />
          </Container>
        </Navbar>
      </>
    );
  }
  
  

export default NavbarTop;