import React from 'react';
import { useNavigate   } from 'react-router-dom';
import { Card ,Typography ,Space ,Statistic} from 'antd';
import { 
  UserOutlined,
} from '@ant-design/icons';
import CountUp from 'react-countup';
const gridStyle = {
    width: '85%',
    textAlign: 'center',
  };

function BoxMember({ title ,count}) {
    const navigate = useNavigate();
    const { Text } = Typography;

    const formatter = (value) => <CountUp end={value} separator="," />;
    
    const member = async () => {
    
        navigate('/Admin/Member');
      };
  return (
<Card.Grid title={title} style={{ ...gridStyle, borderRadius: '10px' }} onClick={member}>

    <Text strong italic style={{ fontSize: '20px', color: '#000000' }}>
      <Statistic title="Member" value={count} formatter={formatter} />
    </Text>
</Card.Grid>


  );
}

export default BoxMember;
