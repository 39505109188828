import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Homepage from './component/Pages/homepage';
import ProfileMember from './component/Pages/Profile';
import Topup from './component/Pages/Topup';
import { UserProvider } from './component/Navbar/Context'; 
import ShopFN from './component/Pages/ShopFN';
import Dashboard from './Admin/Pages/Dashboard';
import DashboardAdmin from './Admin/Pages/Home';
import DashboardMember from './Admin/Pages/Member/Member';
import DashboardAllitem from './Admin/Pages/Allitem/Allitem';
// import Imgpath from './Admin/Pages/Member/img';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter ,Routes, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <UserProvider>
  <Routes>

    <Route path='/' element = {<App></App>}></Route>
    <Route path='/Home/' element = {<Homepage />}></Route>
    <Route path='/Home/Profile' element = {<ProfileMember />}></Route>
    <Route path='/Home/Topup' element = {<Topup />}></Route>
    
    <Route path='/ShopFN/:IDSHOP' element = {<ShopFN />}></Route>
    {/* <Route path='/Page1' element = {<Page1 />}></Route>
    <Route path='/Page2' element = {<Page2 />}></Route> */}

<Route path='/Admin/Home' element = {<Dashboard />}></Route>
<Route path='/Admin/Dashboard' element = {<DashboardAdmin />}></Route>
<Route path='/Admin/Member' element = {<DashboardMember />}></Route>
<Route path='/Admin/AllItem' element = {<DashboardAllitem />}></Route>
{/* <Route path='/Admin/Imgpath' element = {<Imgpath />}></Route> */}

  </Routes>
  </UserProvider>
  </BrowserRouter>
);

reportWebVitals();
