import React, { useState ,useEffect } from 'react';
import { Button as BTN, Modal as AntdModal, notification ,FloatButton , Popconfirm ,InputNumber ,Form,Spin} from 'antd';
import { 
  DollarOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons';
import config from '../../config.json';
import Axios from "axios";
const Modalitem = ({item,Datauser}) => {
  
  const API_URL = config.APP_API_URL;
  const [antdModalOpen, setAntdModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [numq, setnumq] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [okButtonVisible, setOkButtonVisible] = useState(false);


  
  const handleAntdModalOpen = () => {
    setAntdModalOpen(true);
  };

  const handleAntdModalClose = () => {
    setAntdModalOpen(false);
  };
  const openNotificationWithIcon = () => {
    setQuantity(quantity + 1);
    notification.success({
      message: 'เพิ่มสินค้าลงในตะกร้า',
      duration: 2, // ระยะเวลาแสดงเอฟเฟกต์ (วินาที)
      description:
      `You have added ${item.p_name} to your cart.`,
    });
  };
  // useEffect(() => {
  //   if (loading) {
  //     // ทำตามขั้นตอนที่ต้องการก่อนแสดง Spin
  //   }
  // }, [loading]);

  const buyitem = (numq) => {
    setLoading(true);
      // console.log(item.p_id);
    // console.log(Datauser.username);
    // console.log(numq);
    // const getTop10 = () => {
      Axios.get(`${API_URL}/api/Playerbuy/${item.p_id}/${Datauser.username}/${numq}`).then((response) => {
        if (response.data.status === 'ok') {
          console.log(response.data);
          setTimeout(() => {
            notification.success({
              message: 'ซื้อสำเร็จ (Success)',
              duration: 3,
              description: `You buy ${item.p_name} `,
            });
            setLoading(false);
           }, 1000);
          }else{
            notification.error({
              message: response.data.messages,
              duration: 3,
              description: response.data.messages,
            });
            setLoading(false);
          }
        }).catch((error) => {
          
        });
    // };

  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
   {/* {contextHolder} */}
      <BTN onClick={handleAntdModalOpen} style={{
          display: 'grid',
          placeItems: 'center',
          fontSize: '18px',
          marginRight: '15px',
        }}><DollarOutlined /></BTN>

         <BTN onClick={() => openNotificationWithIcon('success')} style={{
          display: 'grid',
          placeItems: 'center',
          fontSize: '18px',
        }}><ShoppingCartOutlined /></BTN>
      {/* <Button onClick={openAntdModal}>Open Antd Modal Programmatically</Button> */}
      <FloatButton.Group shape="circle"  style={{ right: 24 + 60 }}>
      {/* <FloatButton tooltip={<div>custom badge color</div>} badge={{ count: quantity, color: 'blue' }} /> */}
      <FloatButton icon={<ShoppingCartOutlined />} badge={{ count: quantity ,color: 'green' }} />
    </FloatButton.Group>
    
    <AntdModal
  title={<span style={{ fontSize: '30px' }}>{item.p_name}</span>}
  open={antdModalOpen}
  onCancel={handleAntdModalClose}
  cancelButtonProps={{ style: { display: 'none' } }}
  getContainer={false}
  okText="Buy"
  width={700}
  footer={[

<Spin spinning={loading}>
    <Popconfirm
      key="popconfirm"
      title="Are you sure you want to buy this item?"
      onConfirm={() => buyitem(numq) }
      okText="Yes"
      cancelText="No"
    >
      <BTN type="primary">Buy</BTN>
    </Popconfirm>
    </Spin>
  ]}
  // className="custom-modal"
>

  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
    <div>
      <img src={`https://gsv-fn.com/assets/uploads/product/${item.p_img}`} alt="Image" style={{ width: '390px', height: '350px' }} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ wordBreak: 'break-word' }}>
            <p style={{ fontSize: '18px' }}>Type: {item.type_id}</p>
            <p style={{ fontSize: '18px' }}>Detail: {item.p_detail}</p>
            <p style={{ fontSize: '18px' }}>Price: {item.p_price.toLocaleString(undefined, { maximumFractionDigits: 0 })} Points</p>
      </div>
            <div style={{ marginTop: 'auto' }}>
            <Form.Item>
                <p>Quantity (Recommend 1-10)</p>
                <InputNumber min={1} max={10} defaultValue={1} onChange={setnumq} />
            </Form.Item>
    
   
            </div>
      </div>
  </div>

</AntdModal>

      
    </div>
  );
};

export default Modalitem;
