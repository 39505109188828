import React ,{useState}from 'react';
import { useNavigate   } from 'react-router-dom';
import { Button ,Popconfirm ,Spin} from 'antd';
import { 
    LogoutOutlined
} from '@ant-design/icons';
const Logout = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      localStorage.removeItem('Token');
                  localStorage.removeItem('Login');
                  localStorage.removeItem('isPopupClosed');
                  navigate('/');
    }, 1500);
                
  };

  return (
    <div>
    {loading ? (
      <Spin tip="Loading...">
      
    </Spin>
    ) : (
<Popconfirm
        title="Logout ?"
        placement="bottom"
        // description="Are you sure to delete this task?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => handleLogout()}
      >
        <Button ><LogoutOutlined /></Button>
      </Popconfirm>
    )}
  </div>
    
  );
};

export default Logout;
