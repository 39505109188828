import React, { useState, useEffect, useRef } from "react";
import {  Badge, Dropdown, Space, Table ,Image ,Layout,Upload ,Statistic,Tag,Col, Row ,Pagination ,Button as BTN ,notification ,Modal as AntdModal ,Form ,Popconfirm ,Input} from 'antd';
import { 
  CheckCircleOutlined,SyncOutlined,CloseCircleOutlined
} from '@ant-design/icons';
import Axios from "axios";


const { Content } = Layout;

export default function Hispayment({API , headers ,setDatauser}) {


    const [Datahis , setDatahis] = useState([]);


    useEffect(() => { 
      const Hispayget = () => {
          Axios.post(`${API}/api/hispayment`, {}, { headers })
              .then((response) => {
                  if (response.data.status === 'ok') {
                      setDatahis(response.data.His);
                  }
              })
              .catch((error) => {
                  console.error(error);
              });
      };
  
      // เรียกใช้งาน Hispayget เมื่อต้องการ
      Hispayget();
  }, [API, headers]);
  

    const columns = [
        {
          title: 'Reference',
          dataIndex: 'ref',
          key: 'ref',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Package',
          dataIndex: 'package',
          key: 'package',
        },
        {
          title: 'Detail',
          dataIndex: 'detail',
          key: 'detail',
        },
        {
          title: 'Code',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: 'Pay',
          dataIndex: 'typepay',
          key: 'typepay',
        },
        {
          title: 'Status',
          key: 'paystatus',
          dataIndex: 'paystatus',
          render: (_, { paystatus }) => (
            <>
              {paystatus.map((tag, index) => {
                let color,icon;
                if (tag === 'success') {
                  color = 'success';
                  icon = <CheckCircleOutlined />;
                } else if (tag === 'declined') {
                  color = 'volcano';
                  icon = <CloseCircleOutlined />;
                } else if (tag === 'pending') {
                  color = 'gold'; // Change to 'gold' color for 'pending'
                  icon = <SyncOutlined spin/>;
                } else {
                  color = 'default'; // Set a default color for unknown status
                  icon = <CloseCircleOutlined/>;
                }
                return (
                  <Tag color={color} icon={icon} key={index}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
        
        
        {
          title: 'Get',
          key: 'action',
          render: (_, record) => {
            if (record.status === 'success' && record.package === 'Rank') {
                if(record.gif === 'true'){
                  return (
                    <Space size="middle">
                      <Tag color="cyan" key={record.key}>
                      {record.getrank} / received
                    </Tag>
                    </Space>
                  );
                }else{
                  return (
                    <Space size="middle">
                    
                    <Popconfirm
                    placement="topLeft"
                    title="จะรับยศ ใช่ไหม !!"
                    description="Are you sure to Get receive a rank, right?"
                    onConfirm={(e) => Addgroups(record.ref)}
                    // onCancel={cancel}
                    okText="Get"
                    cancelText="No"
                  >
                    <BTN type="primary" size="small">{record.detail}</BTN>
                  </Popconfirm>
                  </Space>
                  );
                }
                      
            } else if(record.status === 'success' && record.getrank !== 'null'){
              if(record.gif === 'true'){
                return (
                  <Space size="middle">
                      {/* <Tag color="success" key={record.key}>
                        {record.getpoints} P
                      </Tag> */}
                    <Tag color="cyan" key={record.key}>
                    {record.getpoints} P / received
                    </Tag>
                </Space>
                );
              }else{
                return (
                  <Space size="middle">
                    
                    <Popconfirm
                    placement="topLeft"
                    title="จะรับยศ ใช่ไหม !!"
                    description="Are you sure to Get receive a rank, right?"
                    onConfirm={(e) => Addgroups(record.ref)}
                    // onCancel={cancel}
                    okText="Get"
                    cancelText="No"
                  >
                    <BTN type="primary" size="small">{record.getrank}</BTN>
                  </Popconfirm>
                  </Space>
                );
              }
            } else if(record.status === 'success' && record.getrank === 'null'){
                    if(record.gif === 'true'){
                      return (
                        <Space size="middle">
                            <Tag color="cyan" key={record.key}>
                              {record.getpoints} P
                            </Tag>
                    
                      </Space>
                      );
                    }
            } else {
              return null; 
            }
          },
        },
        
      ];
    
      const data = Datahis?.map(item => ({
        key: item.id,
        ref: item.ref,
        typepay: item.typepay,
        status: item.Paystatus,
        paystatus: [item.paystatus],
        package: item.Package,
        detail: item.Detail,
        getpoints: item.Getpoints,
        gettokens: item.Gettokens,
        getrank: item.Getrank,
        code: item.code,
        gif: item.Gif,
        totalAmount: item.TotalAmount,
        time: item.date,
      }));



      const Hispaygetsuccess = async () => {
        try {
            const response = await Axios.post(`${API}/api/hispayment`, {}, { headers });
            if (response.data.status === 'ok') {
                setDatahis(response.data.His);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const successauthen = async () => {
      try {
          const response = await Axios.post(`${API}/api/login/authen`, {}, { headers });
          if (response.data.status === 'ok') {
            setDatauser(response.data.users[0]);
          }
      } catch (error) {
          console.error(error);
      }
  };

      const Addgroups = async (ref) => {
        // console.log(ref);
        try{

          const response = await Axios.post(`${API}/api/playergetrank`,{ref:ref},{headers})
          if(response.data.status ==='ok'){
            notification.success({
              message: response.data.messages,
              duration: 3,
              description: response.data.messagesen,
            });
            setTimeout(() => {
              Hispaygetsuccess();
              successauthen();
             }, 1000);
          }else{
            setTimeout(() => {
              notification.warning({
                message: response.data.messages,
                duration: 3,
                description: response.data.messagesen,
              });
              // setisModalOpenRepass(false);
             }, 200);
          }
        }catch(err){
          console.log(err);
        }

      }

    return(
<Content>
<Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }}/>
</Content>
    );
}


