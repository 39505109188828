import React ,{ useState ,useEffect, useRef } from 'react';
import { Badge, Dropdown, Image, Table, Breadcrumb, Layout, Statistic, Input, Button, Space ,Popconfirm ,Modal ,Form ,notification ,Select ,DatePicker ,Upload ,Switch} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/th';
import { useNavigate   } from 'react-router-dom';
import { AllItem } from '../../API/API';
// import CheckDataUser from '../../component/GetDataUser/GetDataUser';
import { 
    HomeOutlined, LineHeightOutlined  ,MinusOutlined ,PlusOutlined ,DownOutlined ,UploadOutlined ,CheckOutlined ,CloseOutlined
} from '@ant-design/icons';
import Logout from '../../component/Logout/Logout';
import config from '../../../config.json';
import Axios from "axios";

const { Header, Content } = Layout;
const { Countdown } = Statistic;
const API_URL = config.APP_API_URL;
const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#fff',
  height: 45,
  paddingInline: 50,
  lineHeight: '45px',
  backgroundColor: '#fff',
  borderRadius: '10px',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#fff',
  borderRadius: '10px',
  marginTop: '10px',
};
const { RangePicker } = DatePicker;


const items = [
  {
    key: '1',
    label: 'Action 1',
  },
  {
    key: '2',
    label: 'Action 2',
  },
];



function DashboardAllitem() {

  const navigate = useNavigate();
  const [allitem, setAllItem] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [allItem] = await AllItem();
        setAllItem(allItem);
      } catch (error) {
        console.log('เกิดข้อผิดพลาดในการดึงข้อมูล');
        navigate('/Admin/Home');
      }
    };

    fetchData();
  }, []);










  const expandedRowRender = () => {
    const columns = [

      {
        title: 'Detail',
        dataIndex: 'Detail',
        key: 'Detail',
        // render: () => <Badge status="success" text="Finished" />,
      },
      {
        title: 'Upgrade Status',
        dataIndex: 'upgradeNum',
        key: 'upgradeNum',
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: () => (
          <Space size="middle">
            <a>Pause</a>
            <a>Stop</a>
            <Dropdown
              menu={{
                items,
              }}
            >
              <a>
                More <DownOutlined />
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ];
    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i.toString(),
        date: '2014-12-24 23:12:00',
        name: 'This is production name',
        upgradeNum: 'Upgraded: 56',
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const columns = [
    {
      title: 'IMG',
      dataIndex: 'p_img',
      key: 'p_img',
      width: 250,
    },
    {
      title: 'Name',
      dataIndex: 'NamePD',
      key: 'NamePD',
    },
    {
      title: 'Type',
      dataIndex: 'p_type',
      key: 'p_type',
    },
    
    {
      title: 'Date',
      dataIndex: 'date_save',
      key: 'date_save',
    },
    {
      title: 'Action',
      key: 'operation',
      render: () => <a>Publish</a>,
    },
  ];
  
  const ReloadRow = async (index, IMG) => {
    // try {
    //   const updatedData = [...allmember];
    //   const updatedMember = {
    //     ...updatedData[index],
    //     p_img: IMG, 
    //   };
    //   updatedData[index] = updatedMember;
    //   setAllMember(updatedData);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  const data = allitem.map((items, index) => {
    const { p_img, p_buleprint, p_buy, p_detail, p_id, p_name, p_price ,date_save,p_type,p_unit} = items;
    return {
      key: index.toString(),
      p_img: (
  <>
  
<Image
  src={`${API_URL}/api/images/Allitem/${p_img}`}
  alt="Item"
  style={{ width: 50, height: 50 ,marginRight:20 }}
/>

<Upload
          // action={`${API_URL}/api/uploadpic/${username}`}
          // listType="picture"
          maxCount={1}
          name="image"
          beforeUpload={(file) => {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
            if (allowedTypes.includes(file.type)) {
              return true;
            } else if(file.status === 'error'){
              
                notification.error({
                message: 'Invalid file type. Please upload a JPEG, PNG, GIF, or WEBP image.',
                duration: 3,
                // description: response.data.messagesen,
              });
              
              return false;
              
              
            }
          }}
          
          onChange={(info) => {
            if (info.file.status !== 'uploading') {
              // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
              notification.success({
                message: `${info.file.name} file uploaded successfully`,
                duration: 3,
                // description: response.data.messagesen,
              });
              const response = info.file.response;
              // console.log(response);

              // message.success();
              // เมื่ออัปโหลดสำเร็จ ดำเนินการอื่น ๆ ที่ต้องการ
              // const { index } = info.file;
              // console.log(info.file.originFileObj)
              ReloadRow(index ,response.IMG);
              // handleUpload(info.file.originFileObj, index);
            } else if (info.file.status === 'error') {
              notification.error({
                message: `${info.file.name} file upload failed.`,
                duration: 3,
                // description: response.data.messagesen,
              });
            }
          }}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
  </>
),

      NamePD: p_name,
      p_type,
      Detail:p_detail,
      Buleprint: p_buleprint,
      date_save: new Date(date_save).toLocaleDateString('en-GB'),
      p_price,
      // groups,
      // ban,
      isExpanded: false,
    };
  });














  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: 'auto', width: '80%', margin: '0 auto', marginTop: '100px' }}>
      <Layout style={{ backgroundColor: 'transparent', cursor: 'pointer' }}>
            <Header style={{ ...headerStyle, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Breadcrumb
            items={[
              {
                href: '/Admin/Dashboard',
                title: <HomeOutlined />,
              },
              {
                title: (
                  <>
                    <span>Item List</span>
                  </>
                ),
              },
            ]}
          />
        </div>
        <Logout/>
      </Header>

      <Content style={{ ...contentStyle, display: 'flex', flexDirection: 'column' }}>
  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' ,marginLeft: '30px' }}>
    <Space>
      <Button size="small">Add Item</Button>
      <a href="/Admin/AllItem/AddType"><Button size="small">Add Type</Button></a>
    </Space>
  </div>
  
  <Table
    columns={columns}
    expandable={{
      expandedRowRender: (record) => (
        <div
          style={{
            margin: 0,
            padding: '8px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <p style={{ margin: 0 }}><b>Price :</b> <span>{record.p_price}</span></p>
          <p style={{ margin: 0 }}><b>Detail :</b> <span>{record.Detail}</span></p>
          <p style={{ margin: 0 }}><b>Buleprint :</b> <span>{record.Buleprint}</span></p>
          <p style={{ margin: 0 }}><b>BP_Saddles :</b> <span>{record.Buleprint}</span></p>
          
        </div>
      ),
    }}
    
    dataSource={data}
    pagination={{
      pageSize: 10, // จำนวนแถวต่อหน้า
      showSizeChanger: false, // แสดงตัวเลือกเปลี่ยนจำนวนแถวต่อหน้า
      pageSizeOptions: ['10', '20', '30'], // ตัวเลือกขนาดหน้า
      showTotal: (total) => `Total ${total} items`, // แสดงจำนวนรายการทั้งหมด
    }}
  />
</Content>




      </Layout>
    </div>
  );
}

export default DashboardAllitem;
