// import '../../App.css';
// import '../homepage/homepage.css';
// import LicenseKey from '../License/License';
import NavbarTop from '../Navbar/NavbarTop';
// import EditProfile from './Profile/Ediname';
// import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
function Homepage() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

    return (
    <div>
    {loading ? (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    ) : (
      <>

<NavbarTop/>

</>

    )}
  </div>
);
    // );
  }
  
  export default Homepage;
  