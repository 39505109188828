import React, { useState, useEffect } from "react";
import {  Badge, Dropdown, Result, Table ,Modal ,Layout,Upload ,Statistic,Tag,Col, Row ,Pagination ,Button as BTN ,notification ,Modal as AntdModal ,Form ,Popconfirm ,Input} from 'antd';
import { 
  CheckCircleOutlined,SyncOutlined,CloseCircleOutlined
} from '@ant-design/icons';



const { Content } = Layout;

export default function Fixpage() { 

    const [isModalOpen, setIsModalOpen] = useState(true);
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
    return(
<Content>
<Modal getContainer={false} open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
<Result
    status="warning"
    title="เว็บไซต์ยังอยู่ในระหว่างการพัฒนา"
    subTitle="The website is still under development."
  />
      </Modal>
    </Content>

    );
}