import React, { useState ,useEffect } from 'react';
import config from '../../config.json';
import Axios from "axios";
import { useParams } from 'react-router-dom';
import './Hamburger.css';
import DataProfile from '../Pages/Profile/DataProfile';
// import TopupComponent from '../Pages/Topup/Topupcompo';
import ModalTopup from '../Pages/Topup/Modaltopup';
import CountUp from 'react-countup';
// import logofn  from '../img/logo/LogoFN-01.png'
// import { Navbar,Figure } from 'react-bootstrap';
import { Layout, Menu ,Divider ,Avatar, Space ,Statistic ,Typography ,Spin } from 'antd';
import { 
    LogoutOutlined,
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Hispayment from '../Pages/Profile/Hispayment';
import { useNavigate ,useLocation  } from 'react-router-dom';
import Home from '../Pages/Home/Home';
import Listitem from '../Listitem/Listitem';

const { Content, Footer, Sider } = Layout;

const SideMenuLayout = ({handleLogoutClick ,Datauser ,Nowpoints}) => {
  const API_URL = config.APP_API_URL;
    const apiKey = config.apiKey;
    const encodedApiKey = btoa(apiKey);
    const tokens = localStorage.getItem("Token");
    const headers = {
      "Authoriztion": `${tokens}`,
      "X-API-Key": encodedApiKey,
      "X-Client-Domain": window.location.origin
    };
  const { IDSHOP } = useParams();
  const FooterFN = config.FOOTERFN;
  const [allitems , setAllItems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const formatter = (value) => <CountUp end={value} separator="," />;
  const { Text } = Typography;


  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };


  useEffect(() => {
    if(location.pathname !== '/Home'){
      setIsLoading(false);
      return;
    }
    const fetchData = async (headers) => {
      try {
        const shopId = IDSHOP || "Home";
        // const tokens = localStorage.getItem("Token");
        // const headers = {
        // "Authoriztion": `${tokens}`,
        // }
        const response = await Axios.post(`${API_URL}/api/allitem/${shopId}`,{},{headers});
        setAllItems(response.data);
      } catch (error) {
        // จัดการข้อผิดพลาดที่เกิดขึ้นในการเรียก API ได้ตามที่คุณต้องการ
      }
    };

    fetchData(headers); // เรียกใช้งานเมื่อคอมโพเนนต์ถูกโหลดครั้งแรก

    // กำหนดการตรวจจับเหตุการณ์เมื่อหน้าจอถูกรีเฟรช
    const handleRefresh = () => {
      fetchData(headers);
    };

    window.addEventListener('beforeunload', handleRefresh);

    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, [IDSHOP]); 

  

  
    const handleMenuClick = async (key, url ) => {
      if( key === 'Home'){
        navigate(url);
      }else{
        navigate(url+'/'+key);
      }
      
      if (key !== '') {
        setIsLoading(true);
        try {
          // const headers = {
          //   "Authoriztion": `${localStorage.getItem("Token")}`,
          // }
          const response = await Axios.post(`${API_URL}/api/allitem/${key}`,{},{headers});
          setAllItems(response.data);
        } catch (error) {
        }
      }else{
        setIsLoading(true);
      }
  
      
    };

    useEffect(() => {
      if (allitems.length > 0) {
        setIsLoading(false);
      }
      console.log(allitems);
    }, [allitems]);


    const Profile = () => {
      navigate('/Home/Profile');    
    };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline">

        <div className="d-flex align-items-center justify-content-center p-0">
        <Space direction="vertical" size={16}>
        <Avatar size={74} src={`${API_URL}/api/images/profile/${Datauser.p_img}`} onClick={Profile} style={{cursor:'pointer' , width:'100%' ,height:'8vh'}}/>
        <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <span style={{ display: 'inline-block', width: '12px', height: '12px', borderRadius: '50%', backgroundColor: 'green', marginRight: '4px' }}></span>
  {/* <span style={{ display: 'inline-block', width: '60px' }}>{Datauser.name}</span> */}
  <span style={{ display: 'inline-block', width: '60px', wordBreak: 'break-all',color: 'white' }}>{Datauser.name}</span>


</div>
 
 
 {Nowpoints > 0 ? <>
  <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' ,color: 'white' ,marginTop: '-15px' }}> 
  <Space size={5}>
 <Statistic value={Nowpoints} formatter={formatter} valueStyle={{textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' ,color: 'white' ,fontSize: '14px',marginTop:'0px' }}/> P
 </Space>
 </span>
 </> 
 : <>
  <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' ,color: 'white' ,marginTop: '-15px' }}> 
  <Space size={5}>
 <Statistic value={Datauser?.Points} formatter={formatter} valueStyle={{textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' ,color: 'white',fontSize: '14px', }}/> P 
 </Space>
 </span>
 </>}

 <ModalTopup />
      </Space>
          </div>
          <Divider style={{ margin: '20px 0' }} />
          <Menu.Item key="01" icon={<PieChartOutlined />} onClick={() => handleMenuClick('Home', '/Home')}>
            Home 
          </Menu.Item>

          {/* <Menu.Item key="1" icon={<img src="https://gsv-fn.com/assets/uploads/icon/RaptorHead_Icon.png" height="30" width="30" alt="IMG" />} onClick={() => handleMenuClick('Dino', '/ShopFN')}>
            Dino
          </Menu.Item>
          <Menu.Item key="2" icon={<img src="https://gsv-fn.com/assets/uploads/icon/Metal_Ingot.png" height="30" width="30" alt="IMG" />} onClick={() => handleMenuClick('Resources', '/ShopFN')}>
          Resources
          </Menu.Item>
          <Menu.Item key="3" icon={<UserOutlined />}>
          Armor
          </Menu.Item>
          <Menu.Item key="4" icon={<TeamOutlined />}>
          Saddles
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Weapons
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Ammunition
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Tools
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Artifacts
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Skin
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Chibi
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Structures
          </Menu.Item>
          <Menu.Item key="5" icon={<FileOutlined />}>
          Boxrandom
          </Menu.Item> */}




          {/* <Divider style={{ margin: '0px 0' }} /> */}
          <Menu.Item key="00" onClick={handleLogoutClick} icon={<LogoutOutlined />}>
    Logout
  </Menu.Item>
        </Menu>
      </Sider>


      <Layout className="site-layout">
    
      <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 10}}>
          {location.pathname !== '/ShopFN/Home' && (
  isLoading ? (
    <div>
      <Home/>
      {/* <iframe src="https://discord.com/widget?id=626846835331301391&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> */}
      {/* <Spin className="Spin" tip="Loading..." size="large" style={{minHeight: 100 }}>.</Spin> */}
      </div>
  ) : (
    

          <>
          {location.pathname === '/Home/Profile' ? (
         
              <DataProfile Datauser={Datauser}/>
           
          ) : (
            <Listitem allitems={allitems} Datauser={Datauser} />
          )}

          </>

  


    
  )
)}

          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          {FooterFN}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default SideMenuLayout;
