import React, { useState ,useEffect } from 'react';
import { Radio, Dropdown, Space, Steps, Popover, Layout, Spin, Statistic, List, Col, Row, Tabs, Button as BTN, notification, Modal as AntdModal, Form, Popconfirm, Input, Button } from 'antd';
import { 
    DollarOutlined,
    SmileOutlined,
    CheckCircleOutlined,
    LoadingOutlined,
  TeamOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import Axios from "axios";
import config from '../../../config.json';
import PayQRS1 from './PayQR/Steps2-3';
import CodeCoupon from './Code';
// import { useNavigate  } from 'react-router-dom';

const { Content, Footer, Sider } = Layout;




export default function ModalTopup() {
  // const navigate = useNavigate();
    const API_URL = config.APP_API_URL;
    const apiKey = config.apiKey;
    const encodedApiKey = btoa(apiKey);    
    const tokens = localStorage.getItem("Token");
    const headers = {
      "Authoriztion": `${tokens}`,
      "X-API-Key": encodedApiKey,
      "X-Client-Domain": window.location.origin
    };

    const [isModalTopup, setIsModalTopup] = useState(false);
    const [packages, setpackages] = useState([]);
    const [selectpackages, setselectpackages] = useState([]);

    const [activeradio, setActiveRadio] = useState(null);
    const [activeButton, setActiveButton] = useState(null);
    const [packid, setpackid] = useState(null);
    const [tagcode, settagcode] = useState([]);

    const [StepsHome, setStepsHome] = useState(1);

    const [PackCode, setPackCode] = useState([]);
    
    const UsePack ={
        SelectPack:`${packid}`,
        SelectPay: `${activeradio}`,
        Code:tagcode,
        Detail:selectpackages,
        PackCode:PackCode
    }
    
    useEffect(() => {
      if(tagcode  !== ''){
        console.log(UsePack);
      }
  }, [tagcode]);
    const OpenTopup = async () => {
      // navigate('/Home/Profile');
        try {
            const response = await Axios.get(`${API_URL}/api/package`,{headers});
            
            // console.log(response.data);
            setpackages(response.data);
            if(response.data.length > 0){
              
              setTimeout(() => {
                setIsModalTopup(true);
            }, 200);
              
            }
            
        } catch (err) {
          console.log(err);
        }
        
      };
    const ModalTopupCancel = () => {
        setIsModalTopup(false);
        setActiveRadio(null);
        setActiveButton(null);
        setPackCode([]);
        settagcode([]);
        // console.log(isModalTopup);
    };

    const buttonStyle = {
        position: 'relative',
        width: '250px', // ปรับความกว้างของปุ่มที่นี่
        height: '80px', // ปรับความสูงของปุ่มที่นี่
        margin: '10px',
      };
      const buttonPointsStyle = {
        position: 'relative',
        width: '250px', // ปรับความกว้างของปุ่มที่นี่
        height: '45px', // ปรับความสูงของปุ่มที่นี่
        margin: '2px',
      };
      
    const activeButtonStyle = {
        ...buttonStyle,
        border: '2px solid blue', // กำหนดเส้นขอบสีฟ้าสำหรับปุ่มที่มีสถานะ active
      };
      const activeButtonPointsStyle = {
        ...buttonPointsStyle,
        border: '2px solid blue', // กำหนดเส้นขอบสีฟ้าสำหรับปุ่มที่มีสถานะ active
      };

      const containerStyle = {
        display: 'flex',
        marginTop: '20px',
      };
    
      const leftStyle = {
        marginLeft: '10px',
        textAlign: 'left',
        width: '200px',
      };
    
      const rightStyle = {
        textAlign: 'right',
      };
      const radioContainerStyle = {
        marginLeft: '20px',
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      };

      const radioStyle = {
        height: '60px',
        width: '100%', // ปรับความกว้างของ Radio ที่นี่
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        // margin:'2px',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
      };

      const activeRadioStyle = {
        ...radioStyle,
        border: '2px solid blue',
        borderRadius: '10px',
      };
    
      const imageStyle = {
        marginRight: '10px', // ระยะห่างระหว่างรูปภาพกับข้อความ
        width: '100%', // ปรับขนาดรูปภาพที่นี่
        height: '50px', // ปรับขนาดรูปภาพที่นี่
        objectFit: 'cover', // กำหนดให้รูปภาพเต็มพื้นที่
      };
      const handleButtonClick = (buttonNumber ,id) => {
        setActiveButton(buttonNumber);
        setpackid(id);
        // console.log(id)
      };
      const onChange = (e) => {
        // console.log('radio checked', e.target.value);
        setActiveRadio(e.target.value);
      };

      
      const renderPopoverContent = (detail) => {
        const detailLines = detail.split('\n');
        return (
          <div>
            {detailLines.map((line, index) => (
              <p key={index} style={{ lineHeight: '0.8', margin: '5px 0' }}>
                {line.startsWith('-') ? <><br />{line.substring(1)}</> : line}
              </p>
            ))}
          </div>
        );
      };
      
      
      

    
    const handleOk = () => {
        if( activeradio !== null || packid !== null ){
          // console.log(packid);
        //   const DataTopup = {  
        //     "tagcode": tagcode,
        //     "payment": `${activeradio}`,
        //     "package": `${packid}`,
        //     "Datauser": {Userid :Datauser.decoded.userid,
        //       UserName :Datauser.decoded.username} 
        //   }
        //   Setdatatopup(DataTopup);
          // console.log(DataTopup);
        //   setIsModalOpen(false);
              if(activeradio === 0){
                
                setStepsHome(StepsHome+1);
                // setIsModalPayment(true);
              }else if(activeradio === 1){
                // setIsModalCardPayment(true);
              }else{

              }
          
        }else{
          if(packid === null){
          
            notification.warning({
              message: `กรุณาเลือกแพ็คเกจ`,
            });
          }else if(activeradio === null){
            notification.warning({
              message: `กรุณาเลือกช่องทางการชำระเงิน`,
            });
          }
         

        }
      
      };


      useEffect(() => {
        // ค้นหา package ที่มี tu_id เท่ากับ packid
        const selectedPackage = packages.find(pack => pack.tu_id === packid);
        if (selectedPackage) {
          setselectpackages([selectedPackage]);
        } else {
          setselectpackages([]);
        }
    }, [packid, packages]);


    const onChangetab = (key) => {
      // console.log(key);
    };
    const Tabss = [
      {
        key: '1',
        label: 'Rank',
        children: packages.map((packageItem, index) =>
          packageItem.pk_name === 'Rank' && (
            <Popover
              key={index}
              placement="left"
              content={renderPopoverContent(packageItem.tu_detail)} 
              title="Detail"
              trigger="hover"
            >
              <Button
                style={activeButton === index ? activeButtonStyle : buttonStyle}
                onClick={() => handleButtonClick(index, packageItem.tu_id)}
              >
                <p>{packageItem.tu_name}</p>
                <span>{packageItem.tu_timeday} Day</span>
              </Button>
            </Popover>
          )
        ),
      },
      {
        key: '2',
        label: 'Points',
        children:  packages.map((packageItem, index) =>
        packageItem.pk_name === 'Points' && (
          <Popover
            key={index}
            placement="left"
            content={renderPopoverContent(packageItem.tu_detail)} 
            title="Detail"
            trigger="hover"
          >
            <Button
              style={activeButton === index ? activeButtonPointsStyle : buttonPointsStyle}
              onClick={() => handleButtonClick(index, packageItem.tu_id)}
            >
              <span>{packageItem.tu_name}</span>
              {/* <span>{packageItem.tu_timeday}</span> */}
            </Button>
          </Popover>
        )
      ),
      }
    ];
    

    return(
        <>
  

  <BTN size='small' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'none', border: 'none', color: 'white' ,marginTop: '-15px', }} onClick={() => OpenTopup()}>
    <Space><DollarOutlined style={{ color: 'white', fontSize: '20px' }} />Topup</Space>
</BTN>













        <AntdModal 
        title="Topup" 
        getContainer={false}
        onCancel={ModalTopupCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        open={isModalTopup}
        okText="Next"
        maskClosable={false}
        footer = {null}
        // onOk={handleOk}
        width={700}
        >



{StepsHome === 1 ? 

<Content style={{marginBottom:'20px'}}>
<Row>
      <Col span={10}>
          <Content>
          {/* {renderButtons()} */}
          <Tabs defaultActiveKey="1" items={Tabss} onChange={onChangetab} />

          </Content>
      </Col>


      <Col span={14}>
      <Content>
        {packages.map((packageItem, index) => (
          <>
              {activeButton === index && packageItem.pk_name === 'Rank'&& (
              <>
            <div style={containerStyle}>
                <div style={{ ...leftStyle, flex: 1 }}>
                <p>RANK {packageItem.tu_name}</p>
                <p>TimeDay</p>
                <p>Discount</p>
                <p>Total</p>
                </div>
                <div style={{ ...rightStyle, flex: 1 }}>
                <p>{packageItem.tu_price} บาท  / {packageItem.tu_priceUS} USD </p>
                {packageItem.tu_name ==='30Day' ? <p>ไม่มีวันหมด / {packageItem.tu_timeday} </p> : <p>{packageItem.tu_timeday} วัน  / {packageItem.tu_timeday} Day </p> }
                {PackCode.Type === '%' ? (
                              <p>
                                {PackCode.Dis} %
                              </p>
                            ) : PackCode.Type === 'baht' ? (
                              <p>{(PackCode.Dis).toFixed(2)} บาท / {PackCode.DisUS} USD</p>
                            ) : (
                              <p>0.00 บาท / 0.00 USD</p>
                            )}

                {PackCode.Type === '%' ? (
                              <p>
                                {(packageItem.tu_price - (packageItem.tu_price * (PackCode.Dis / 100))).toFixed(2)} บาท
                                / {(packageItem.tu_priceUS - (packageItem.tu_priceUS * (PackCode.Dis / 100))).toFixed(2)} USD
                              </p>
                            ) : PackCode.Type === 'baht' ? (
                              <p>{packageItem.tu_price - PackCode.Dis} บาท / {(packageItem.tu_priceUS - PackCode.DisUS).toFixed(2)} USD</p>
                            ) : (
                              <p>{packageItem.tu_price} บาท  / {packageItem.tu_priceUS} USD </p>
                            )}
                </div>
              </div>
            </> 
            )}
            {activeButton === index && packageItem.pk_name === 'Points'&& (
              <>
            <div style={containerStyle}>
                <div style={{ ...leftStyle, flex: 1 }}>
                {PackCode.Type === 'X' ? <p>{packageItem.tu_addpoints}*{PackCode.Dis} = {packageItem.tu_addpoints * PackCode.Dis} Points</p> : <> <p>{packageItem.tu_name}</p></>}
                <p>TimeDay</p>
                <p>Discount</p>
                <p>Total</p>
                </div>
                <div style={{ ...rightStyle, flex: 1 }}>
                <p>{packageItem.tu_price} บาท  / {packageItem.tu_priceUS} USD </p>
                {packageItem.tu_addrank ==='VIP' || packageItem.tu_addrank ==='SVIP' ? <p>Free {packageItem.tu_addrank} {packageItem.tu_timeday} day </p> : <p>Free</p> }


                {PackCode.Type === "%" ? (
                              <p>
                                {PackCode.Dis} %
                              </p>
                            ) : PackCode.Type === 'baht' ? (
                              <p>{(PackCode.Dis).toFixed(2)} บาท / {PackCode.DisUS} USD</p>
                            ) : (
                              <p>0.00 บาท / 0.00 USD</p>
                            )}

                {PackCode.Type === "%" ? (
                              <p>
                                {(packageItem.tu_price - (packageItem.tu_price * (PackCode.Dis / 100))).toFixed(2)} บาท
                                / {(packageItem.tu_priceUS - (packageItem.tu_priceUS * (PackCode.Dis / 100))).toFixed(2)} USD
                              </p>
                            ) : PackCode.Type === 'baht' ? (
                              <p>{packageItem.tu_price - PackCode.Dis} บาท / {(packageItem.tu_priceUS - PackCode.DisUS).toFixed(2)} USD</p>
                            ) : (
                              <p>{packageItem.tu_price} บาท  / {packageItem.tu_priceUS} USD </p>
                            )}
                </div>
              </div>
            </> 
            )}
          </>
        ))}
          </Content>
          <Content>
          
          <CodeCoupon setTagcode={settagcode} headers={headers} API={API_URL} setPackCode={setPackCode}/>
          
        </Content>
<span>ใส่โค้ด FNX2 เพื่อใช้สิทธิ์พ้อย x2 </span>
              <Content style={radioContainerStyle}>
      <Radio.Group onChange={onChange} value={activeradio}>
        <div style={activeradio === 0 ? activeRadioStyle : radioStyle}>
        
                    <div style={{ ...leftStyle, flex: 1 }}>
                    <Radio value={0}><span>พร้อมเพย์ (QR)</span> </Radio>
                    </div>
                    <div style={{ ...rightStyle, flex: 1 }}>
                    <img src="https://blog.loga.app/wp-content/uploads/2019/07/payment.png" alt="Icon 1" style={imageStyle} />
                    </div>
          
        </div>
        <div style={activeradio === 1 ? activeRadioStyle : radioStyle}>
              <div style={{ ...leftStyle, flex: 1 }}>
              <Radio value={1} disabled><span>บัตรเครดิด (VISA) </span> </Radio>
              </div>
              <div style={{ ...rightStyle, flex: 1 }}>
              <img src="https://www.visa.co.th/dam/VCOM/partner-with-us/visapos-640x360.jpg" alt="Icon 2" style={imageStyle} />
              </div>
        
        </div>
        <div style={activeradio === 2 ? activeRadioStyle : radioStyle}>
                    <div style={{ ...leftStyle, flex: 1 }}>
                    <Radio value={2} disabled><span>ทรูมันนี่ (Wallet) </span> </Radio>
                    </div>
                    <div style={{ ...rightStyle, flex: 1 }}>
                    <img src="https://yakkaw.com/wp-content/uploads/2022/02/22-1.png" alt="Icon 3" style={imageStyle} />
                    </div>
          </div>
      </Radio.Group>

 
      
  
    </Content>
    {activeButton !== null && activeradio  !== null ? <>
      <div style={containerStyle}>   
          <div style={{ ...rightStyle, flex: 1 }}>
          <BTN onClick={handleOk}>Next</BTN>
            </div> 
                
            </div>  
    </>
    :
    <>
    
    </>}
       

      </Col>
 
    </Row>
    </Content>
 : <></>}





{/* //////////////////////////////////////////////////////////////////////////////// 222222222222222222222222222222222222222222222 */}


{StepsHome === 2 ? 
<>

<PayQRS1 API={API_URL} headers={headers} Datapack={UsePack} StepsHome={StepsHome} setStepsHome={setStepsHome} setIsModalTopup={setIsModalTopup} setActiveRadio={setActiveRadio}setActiveButton={setActiveButton} setPackCode={setPackCode} settagcode={settagcode}/>

</>
: <></>}




























      </AntdModal>

        </>
    ); 
}