import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Logo from '../img/logo/LogoFN-01.png';
import React, { useState ,useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Image as IMG ,Tabs ,Button} from 'antd';
import Axios from "axios";
import config from '../../config.json';
// import config from '../../config.json';
function Header() {
  

    const [ pvenomod , setMappvenomod] = useState([]);
    const [ pvemod , setMappvemod] = useState([]);
    const [ pvpnomod , setMappvpnomod] = useState([]);
    const API_URL = config.APP_API_URL;
    const PARTIMG = config.APP_URL_IMG;

    const apiKey = config.apiKey;
    const encodedApiKey = btoa(apiKey);
    const headers = {
      "X-API-Key": encodedApiKey,
      "X-Client-Domain": window.location.origin
    };
    useEffect(() => {
      const MySwal = withReactContent(Swal);
      Axios.get(API_URL+"/api/allserver" ,{headers}).then((response) => {
        setMappvenomod(response.data.Nomodpve);
        setMappvemod(response.data.Modpve);
        setMappvpnomod(response.data.Nomodpvp);
      }).catch((error) => {
        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          title: <strong>ไม่สามารถใช้งานได้</strong>,
          html: <i>not available!</i>,
          icon: 'error'
        })
      });
    }, [API_URL]);

    const [activeTab, setActiveTab] = useState('1'); // สถานะเริ่มต้นของแท็บที่ถูกเลือก

    const handleTabChange = (key) => {
      setActiveTab(key);
    };

    const tabItems = [
      {
        label: "PveNoMod",
        key: "1",
        backgroundColor: "#ff0000",
        color: "#ffffff",
      },
      // {
      //   label: "PvPveNoMod",
      //   key: "2",
      // },
      {
        label: "PvPNoMod",
        key: "3",
        backgroundColor: "#ff0000",
        color: "#ffffff",
      },
      {
        label: "PvEMod",
        key: "4",
        backgroundColor: "#ff0000",
        color: "#ffffff",
      },
      // {
      //   label: "PvPveMod",
      //   key: "5",
      // },
      // {
      //   label: "PvPMod",
      //   key: "6",
      // },
    ];

    return (
      <>

    <Container>
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' ,marginTop:'40px' }}>
    <Row>
        <Col><Image src={Logo} width="100%" height="400" roundedCircle /></Col>
      </Row>
    </Container>
    <Tabs
        defaultActiveKey="1"
        centered
        activeKey={activeTab} // กำหนดให้แสดงเนื้อหาตามแท็บที่ถูกเลือก
        onChange={handleTabChange} // ตรวจสอบการเปลี่ยนแท็บ
        items={tabItems.map((item) => ({
          ...item,
          label: <span style={{ color: item.color }}>{item.label}</span>,
          style: { backgroundColor: item.backgroundColor },
        }))}
      />


    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5" >

    {activeTab === '1' && (<>
      {pvenomod.map((mappve_nomod) => (
          <div className="col" key={mappve_nomod.key}>
                    <div class="card shadow-sm">
                    <IMG src={`${PARTIMG}${mappve_nomod.s_pic}`} width="100%" class="img-fluid" />
                        <div class="card-body">
                            <p class="card-text"><h5 style={{ textAlign: 'center'}}>{mappve_nomod.s_name}</h5></p>
                            <div style={{ textAlign: 'center'}}>
                              <p style={{ marginBottom: '0px' }}>OPEN: {`${new Date(mappve_nomod.s_date).getDate()}/${new Date(mappve_nomod.s_date).getMonth() + 1}/${new Date(mappve_nomod.s_date).getFullYear()}`}</p>
                              <p style={{ marginBottom: '0px'}}>IPSteam: {mappve_nomod.s_ipserver}</p>
                              <p style={{ marginBottom: '15px'}}>IPEpic: {mappve_nomod.s_ipserverepic}</p>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-sm btn-outline-secondary me-2">NOW ONLINE <span  
                                style={{ color: 'red'}}>{mappve_nomod.PlayerCount}</span></button>
                                <a class="btn btn-outline-success btn-sm" href={`steam://connect/${mappve_nomod.s_ipserver}`}>JOINGAME</a>

                                <small class="text-muted"></small>
                            </div>
                        </div>
                    </div>

            <br></br> <br></br>
            </div>
            ))}

    </>)}
    {activeTab === '3' && (<>
      {pvpnomod.map((mappve_nomod) => (
          <div className="col" key={mappve_nomod.key}>
                    <div class="card shadow-sm">
                    <IMG src={`${PARTIMG}${mappve_nomod.s_pic}`} width="100%" class="img-fluid" />
                        <div class="card-body">
                            <p class="card-text"><h5 style={{ textAlign: 'center'}}>{mappve_nomod.s_name}</h5></p>
                            <div style={{ textAlign: 'center'}}>
                              <p style={{ marginBottom: '0px' }}>OPEN: {`${new Date(mappve_nomod.s_date).getDate()}/${new Date(mappve_nomod.s_date).getMonth() + 1}/${new Date(mappve_nomod.s_date).getFullYear()}`}</p>
                              <p style={{ marginBottom: '0px'}}>IPSteam: {mappve_nomod.s_ipserver}</p>
                              <p style={{ marginBottom: '15px'}}>IPEpic: {mappve_nomod.s_ipserverepic}</p>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-sm btn-outline-secondary me-2">NOW ONLINE <span  
                                style={{ color: 'red'}}>{mappve_nomod.PlayerCount}</span></button>
                                <a class="btn btn-outline-success btn-sm" href={`steam://connect/${mappve_nomod.s_ipserver}`}>JOINGAME</a>

                                <small class="text-muted"></small>
                            </div>
                        </div>
                    </div>

            <br></br> <br></br>
            </div>
            ))}

    </>)}
    {activeTab === '4' && (<>
      {pvemod.map((mappve_nomod) => (
          <div className="col" key={mappve_nomod.key}>
                    <div class="card shadow-sm">
                    <IMG src={`${PARTIMG}${mappve_nomod.s_pic}`} width="100%" class="img-fluid" />
                        <div class="card-body">
                            <p class="card-text"><h5 style={{ textAlign: 'center'}}>{mappve_nomod.s_name}</h5></p>
                            <div style={{ textAlign: 'center'}}>
                              <p style={{ marginBottom: '0px' }}>OPEN: {`${new Date(mappve_nomod.s_date).getDate()}/${new Date(mappve_nomod.s_date).getMonth() + 1}/${new Date(mappve_nomod.s_date).getFullYear()}`}</p>
                              <p style={{ marginBottom: '0px'}}>IPSteam: {mappve_nomod.s_ipserver}</p>
                              <p style={{ marginBottom: '15px'}}>IPEpic: {mappve_nomod.s_ipserverepic}</p>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-sm btn-outline-secondary me-2">NOW ONLINE <span  
                                style={{ color: 'red'}}>{mappve_nomod.PlayerCount}</span></button>
                                <a class="btn btn-outline-success btn-sm" href={`steam://connect/${mappve_nomod.s_ipserver}`}>JOINGAME</a>

                                <small class="text-muted"></small>
                            </div>
                        </div>
                    </div>

            <br></br> <br></br>
            </div>
            ))}

    </>)}

    


        
    </div> 
    </Container>
    
      </>
    );
  }
  

export default Header;