import React , { useState ,useEffect  } from 'react';
import { Card, List ,Input ,Image} from 'antd';
import Modalitem from './Modalitem';
const { Meta } = Card;


const Listitem = ({allitems,Datauser}) => {
  const data = allitems;
  const [searchText, setSearchText] = useState('');
  const [columns, setColumns] = useState(5);

  const filteredData = data.filter((allitems) =>
  allitems.p_name.toLowerCase().includes(searchText.toLowerCase())
  );
  useEffect(() => {
    const handleResize = () => {
      // ปรับค่าคอลัมน์ตามขนาดหน้าจอ
      if (window.innerWidth <= 454) {
        setColumns(1); // ในรูปแบบโทรศัพท์ให้เป็นคอลัมน์เดียว
      } else if (window.innerWidth <= 820){
        setColumns(2); // 
      } else if (window.innerWidth <= 1200){
        setColumns(3); // 
      } else if (window.innerWidth <= 1600){
        setColumns(4); // 
      }else if (window.innerWidth <= 2000){
        setColumns(5); // 
      }else if (window.innerWidth <= 2400){
        setColumns(6); // 
      }else{
        setColumns(7); // 
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // เรียกใช้ฟังก์ชันเมื่อคอมโพเนนต์ถูกโหลด

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
        <Input.Search
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 16 , width: 300 }}
          />
          <div  style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          maxHeight: 'calc(100vh - 120px)',
        }}>
      <List
        grid={{ gutter: 16, column: columns }} 
        dataSource={filteredData}
        renderItem={(allitems) => (
          <List.Item>
             
            <Card
              hoverable
              style={{ width: 280 ,height: 350 }}
              cover={<Image alt="IMG" src={`https://gsv-fn.com/assets/uploads/product/${allitems.p_img}`} 
              style={{ width: '100%', height: '27vh' }} />}

            >
              <Meta title={allitems.p_name} description=" " />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Modalitem item={allitems} Datauser={Datauser}/>
              </div>
            </Card>

          </List.Item>
        )}
      />
      </div>
    </>
  );
};

export default Listitem;
