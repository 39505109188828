import React ,{ useState ,useEffect, useRef } from 'react';
import { Badge, Dropdown, Image, Table, Breadcrumb, Layout, Statistic, Input, Button, Space ,Popconfirm ,Modal ,Form ,notification ,Select ,DatePicker ,Upload ,Switch} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/th';
import { useNavigate   } from 'react-router-dom';
import { AllMember } from '../../API/API';
// import CheckDataUser from '../../component/GetDataUser/GetDataUser';
import { 
    HomeOutlined, UserOutlined ,MinusOutlined ,PlusOutlined ,DownOutlined ,UploadOutlined ,CheckOutlined ,CloseOutlined
} from '@ant-design/icons';
import Logout from '../../component/Logout/Logout';
import config from '../../../config.json';
import Axios from "axios";

const { Header, Content } = Layout;
const { Countdown } = Statistic;
const API_URL = config.APP_API_URL;
const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#fff',
  height: 45,
  paddingInline: 50,
  lineHeight: '45px',
  backgroundColor: '#fff',
  borderRadius: '10px',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#fff',
  borderRadius: '10px',
  marginTop: '10px',
};
const { RangePicker } = DatePicker;

function DashboardMember() {
  moment.locale('th');
  const navigate = useNavigate();
  const [form] = Form.useForm(); // ใช้ Hook Form.useForm()
  const [isChecked, setIsChecked] = useState(true);
  const [allmember, setAllMember] = useState([]);
  const [allgroups, setAllGroups] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalProfile, setIsModalProfile] = useState(false);
  const [isModalOpenGroups, setIsModalOpenGroups] = useState(false);
  const [isModalDeleteGroups, setIsModalDeleteGroups] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentIndexProfile, setCurrentIndexProfile] = useState(null);
  const [currentIndexGruops, setCurrentIndexGruops] = useState(null);
  const [currentIndexDeleteGruops, setCurrentIndexDeleteGruops] = useState(null);
  // const [currentIndexIMG, setCurrentIndexIMG] = useState(null);
  const [SteamEdit, EditsetSteam] = useState('');
  const [NameEdit, EditsetName] = useState('');
  const [PlayerIDEdit, EditsetPlayerID] = useState('');
  const [DateEdit, EditsetDate] = useState('');
  const [X2Edit, EditsetX2] = useState('');
  const [BanEdit, EditsetBan] = useState('');

  // const [Name, setName] = useState('');
  // const [X2, setX2] = useState('');
  // const [Repass, ResetPass] = useState('');
  // console.log(allgroups)

  const ClearProfile = () => {
    EditsetSteam('');
    EditsetName('');
    EditsetDate('');
    EditsetX2('');
    EditsetBan('');
    EditsetPlayerID('');
    // setX2('');
    setTimeout(() => {
    // console.log('ClearProfile ' +SteamEdit +' ' +NameEdit+' '+ DateEdit);
  }, 1000);
  };
  const showModal = (index) => {
    setIsModalOpen(true);
    setCurrentIndex(index);
    EditsetSteam(allmember[index].username);
  };
  
  const showProfile = (index) => {

    setCurrentIndexProfile(index);
    const selectedMember = allmember[index];
    Axios.get(`${API_URL}/api/getdatauser/${selectedMember.username}`).then((response) => {
      if (response.data.status === 'ok') {
      
          notification.success({
            message: response.data.messages,
            duration: 3,
          });
          const formattedDate = new Date(response.data.Datauser[0].date_save).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });

        EditsetSteam(response.data.Datauser[0].username);
        EditsetName(response.data.Datauser[0].name);
        EditsetX2(response.data.Datauser[0].x2);
        EditsetBan(response.data.Datauser[0].ban);
        EditsetPlayerID(response.data.Datauser[0].pyid)
        EditsetDate(formattedDate);
        setIsModalProfile(true);
  
      }else{
        notification.error({
          message: response.data.messages,
          duration: 3,
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });

  };
  

  const showAddGroups = (index) => {
    setIsModalOpenGroups(true);
    setCurrentIndexGruops(index);
    EditsetSteam(allmember[index].username);
  };
  const showDeleteGroups = (index) => {
    setIsModalDeleteGroups(true);
    setCurrentIndexDeleteGruops(index);
    EditsetSteam(allmember[index].username);
  };
  const handleOk = () => {
    // ClearFrom();
    ClearProfile();
    setIsModalOpen(false);
    setIsModalOpenGroups(false);
    setIsModalDeleteGroups(false);
    setIsModalProfile(false);
  };
  const handleCancel = () => {
    // ClearFrom();
    ClearProfile();
    setIsModalOpen(false);
    setIsModalOpenGroups(false);
    setIsModalDeleteGroups(false);
    setIsModalProfile(false);

  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [allMember ,allGroups] = await AllMember();
        setAllMember(allMember);
        setAllGroups(allGroups)
      } catch (error) {
        console.log('เกิดข้อผิดพลาดในการดึงข้อมูล');
        navigate('/Admin/Home');
      }
    };

    fetchData();
  }, []);


  


  const handleEdit = (index) => {
    // ทำสิ่งที่ต้องการเมื่อคลิกปุ่ม Edit
    // console.log('Edit member at index:', index);
    const resetPassword = form.getFieldValue('resetpassword');
    const memberToEdit = allmember[index].id;
    // console.log('Edit member:', memberToEdit.id);
    // console.log('Reset Password:', resetPassword);

    const updatedData = {
      resetpassword: resetPassword, // ค่าที่ต้องการอัปเดต
      // รายละเอียดข้อมูลอื่น ๆ ที่ต้องการอัปเดต
    };
    Axios.put(`${API_URL}/api/repassmember/${memberToEdit}`, updatedData).then((response) => {
      if (response.data.status === 'ok') {
        setTimeout(() => {
          notification.success({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
          setIsModalOpen(false);
         }, 1000);
      }else{
        notification.error({
          message: response.data.messages,
          duration: 3,
          description: response.data.messages,
        });
        setLoading(false);
      }
    })
    .catch((error) => {
      // ดำเนินการหากมีข้อผิดพลาดในการอัปเดต
      console.error(error);
    });

  };
  

  
  const handleDelete = (index) => {
    // ทำสิ่งที่ต้องการเมื่อคลิกปุ่ม Delete
    // console.log('Delete member at index:', index);
    const memberToDelete = allmember[index].id;
    // console.log('Delete member:', memberToDelete.id);
    Axios.put(`${API_URL}/api/deletemember/${memberToDelete}`).then((response) => {
      if (response.data.status === 'ok') {
        // console.log(response.data);

        setTimeout(() => {
          notification.success({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
           // อัปเดตข้อมูลหลักของตาราง
        const updatedData = [...allmember];
        updatedData.splice(index, 1);
        setAllMember(updatedData);

        // อัปเดตแถวที่ขยาย
        const updatedExpandedRowKeys = expandedRowKeys.filter(
          (key) => key !== index.toString()
        );
        setExpandedRowKeys(updatedExpandedRowKeys);
         }, 1000);

        }else{
          notification.error({
            message: response.data.messages,
            duration: 3,
            description: response.data.messages,
          });
          setLoading(false);
        }
      }).catch((error) => {
        
      });
  };
  
  


  const expandedRowRender = (record) => {
    if (record.groups === null) {
      return null; // ไม่แสดงข้อมูลเมื่อเป็น null
    }
    const columns = [
      {
        title: 'Groups',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Timeout',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Countdown',
        dataIndex: 'endDate',
        key: 'countdown',
        render: (endDate) => {
          const now = new Date();
          const formattedEndDate = new Date(endDate).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });

          return (
            <Countdown
              value={endDate}
              format="D วัน H ชั่วโมง m นาที s วิ"
              suffix={now > endDate ? ' (หมดอายุ)' : ''}
            />
          );
        },
      },
      {
        title: 'Status',
        key: 'state',
        render: (_, record) => {
          const now = new Date();
          const endDate = new Date(record.endDate);
          const isFinished = endDate > now;

          return isFinished ? (
            <Badge status="success" text="Not expired" />
          ) : (
            <Badge status="error" text="Expired" />
          );
        },
      },
    ];

    const groups = record.groups.split(',');
    const groupData = groups.map((group, index) => {
      const [name, startDate, endDate] = group.replace(/\[|\]/g, '').split('|');

      const formattedStartDate = new Date(startDate).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
  
      const formattedEndDate = new Date(endDate).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
  
      return {
        key: `${index}`,
        name,
        date: `${formattedStartDate} - ${formattedEndDate}`,
        endDate: new Date(endDate),
      };
    });

    return (
      <Table columns={columns} dataSource={groupData} pagination={false} />
    );
  };

  const handleRowClick = (record) => {
    const updatedRowKeys = expandedRowKeys.includes(record.key)
      ? expandedRowKeys.filter((key) => key !== record.key)
      : [...expandedRowKeys, record.key];
    setExpandedRowKeys(updatedRowKeys);
  };

  const items = [
    {
      key: '0',
      label: 'Profile',
    },
    {
      key: '1',
      label: 'AddGroups',
    },
    {
      key: '2',
      label: 'DeleteGroups',
    },
    {
      key: '3',
      label: 'Repass',
    },
    {
      key: '4',
      label: 'Delete',
    },
  ];


  const columns = [
    {
      title: 'IMG',
      dataIndex: 'p_img',
      key: 'p_img',
      width: 250,
    },
    {
      title: 'SteamID/EpicID',
      dataIndex: 'SteamID',
      key: 'SteamID',
      render: (text, record) => (
        <span style={{ backgroundColor: record.ban === 'true' ? 'red' : 'transparent' }}>{text}</span>
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'PlayerID',
      dataIndex: 'PlayerID',
      key: 'PlayerID',
    },
    {
      title: 'Date',
      dataIndex: 'date_save',
      key: 'date_save',
    },
    {
      title: 'X2',
      dataIndex: 'x2',
      key: 'x2',
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => {
        const menuProps = {
          items,
          onClick: (e) => handleMenuClick(e, index),
        };
    
        const handleMenuClick = (e, index) => {
          // console.log('idmember', record.idmember);
          // console.log('key', record.key);
          if (e.key === '0') {
            showProfile(record.idmember);
          }
          if (e.key === '1') {
            showAddGroups(record.idmember);
          }
          if (e.key === '2') {
            showDeleteGroups(record.idmember);
          }
          if (e.key === '3') {
            showModal(record.idmember);
          }
          if (e.key === '4') {
            confirmDelete(record.idmember);
          }

        };
        const confirmDelete = (index) => {
          Modal.confirm({
            title: 'Delete the task',
            content: 'Are you sure to delete this task?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleDelete(index),
          });
        };
    
        return (
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                {/* {record.idmember} */}
                <DownOutlined />
                {/* {record.key} */}
              </Space>
            </Button>
          </Dropdown>
        );
      },
    }


    
  ];
  

  const ReloadRow = async (index, IMG) => {
    try {
      const updatedData = [...allmember];
      const updatedMember = {
        ...updatedData[index],
        p_img: IMG, 
      };
      updatedData[index] = updatedMember;
      setAllMember(updatedData);
    } catch (error) {
      console.error(error);
    }
  };
  
  const data = allmember.map((member, index) => {
    const { p_img,id, username, name, pyid, date_save, x2, groups ,ban } = member;
    return {
      key: index.toString(),
      idmember: id,
      p_img: (
  <>
  
<Image
  src={`${API_URL}/api/images/profile/${p_img}`}
  alt="User Image"
  style={{ width: 50, height: 50 ,marginRight:20 }}
/>

<Upload
          action={`${API_URL}/api/uploadpic/${username}`}
          // listType="picture"
          maxCount={1}
          name="image"
          // onChange={handleOnChange(index)}
          beforeUpload={(file) => {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
            if (allowedTypes.includes(file.type)) {
              return true;
            } else if(file.status === 'error'){
              
                notification.error({
                message: 'Invalid file type. Please upload a JPEG, PNG, GIF, or WEBP image.',
                duration: 3,
                // description: response.data.messagesen,
              });
              
              return false;
              
              
            }
          }}
          
          onChange={(info) => {
            if (info.file.status !== 'uploading') {
              // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
              notification.success({
                message: `${info.file.name} file uploaded successfully`,
                duration: 3,
                // description: response.data.messagesen,
              });
              const response = info.file.response;
              // console.log(response);

              // message.success();
              // เมื่ออัปโหลดสำเร็จ ดำเนินการอื่น ๆ ที่ต้องการ
              // const { index } = info.file;
              // console.log(info.file.originFileObj)
              ReloadRow(index ,response.IMG);
              // handleUpload(info.file.originFileObj, index);
            } else if (info.file.status === 'error') {
              notification.error({
                message: `${info.file.name} file upload failed.`,
                duration: 3,
                // description: response.data.messagesen,
              });
            }
          }}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
  </>
),

      SteamID: username,
      name: name,
      PlayerID: pyid,
      date_save: new Date(date_save).toLocaleDateString('en-GB'),
      x2,
      groups,
      ban,
      isExpanded: false,
    };
  });
  
  const filteredData = data
  .filter((member) => {
    const { name, SteamID } = member;
    const lowerCaseName = name.toLowerCase();
    const searchString = searchText.toLowerCase();
    return (
      lowerCaseName.includes(searchString) ||
      SteamID.toString().includes(searchString)
    );
  })
  .map((member, index) => ({
    ...member,
    key: index.toString(),
  }));
  
  const options = allgroups.map((group) => ({
    value: group.GroupName,
    label: `(${group.Id}) ${group.GroupName} `,
  }));
  
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState('');

  const ClearFrom = () => {
    setSelectedGroups('');
    setSelectedRange(null);
  };

  const handlePresetClick = (value) => {
    setSelectedRange(value);
  };
  
  const handleRangeChange = (dates) => {
    // console.log('Selected Range:', dates);
  setSelectedRange(dates);
  };
  const handleChange = (value) => {
    // console.log(value); 
    setSelectedGroups(value)
    
  };

  const handleEditGroups = (index) => {
    const memberToAddGroups = allmember[index].id;
    const startDateFormatted = selectedRange[0].format('YYYY-MM-DD HH:mm:ss');
    const endDateFormatted = selectedRange[1].format('YYYY-MM-DD HH:mm:ss');
    const groupData = `[${selectedGroups}|${endDateFormatted}|${startDateFormatted}]`;

    const startDate = new Date(startDateFormatted);
    const endDate = new Date(endDateFormatted);
    const timeDiff = Math.abs(endDate - startDate);
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    // console.log("จำนวนวัน:", daysDiff);

    // console.log('index:', memberToAddGroups, 'value:', selectedGroups, 'startDate:', startDate, 'endDate:', endDate);
   
   
    const updatedData = {
      groupData: groupData,
    };
    Axios.put(`${API_URL}/api/editgroupsmember/${memberToAddGroups}/${daysDiff}`,updatedData).then((response) => {
      if (response.data.status === 'ok') {

        setTimeout(() => {
          notification.success({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
                     // อัปเดตข้อมูลในตารางโดยตรง
          const updatedData = [...allmember];
          const updatedMember = {
            ...updatedData[index],
            groups: response.data.groups, // อัปเดตฟิลด์ groups เท่านั้น
          };
          updatedData[index] = updatedMember;
          setAllMember(updatedData);
          // ClearFrom();
         }, 1000);

        }else{
          notification.error({
            message: response.data.messages,
            duration: 3,
            description: response.data.messages,
          });
          setLoading(false);
        }
      }).catch((error) => {
        
      });
  };
  
  const handleDeleteGroups = (index) => {
    const memberToDeleGroups = allmember[index].id;

    const updatedData = {
      GroupsName: selectedGroups,
      Delete:'Delete',
    };
    // console.log(memberToDeleGroups);
    Axios.put(`${API_URL}/api/editgroupsmember/${memberToDeleGroups}/0`,updatedData).then((response) => {
      if (response.data.status === 'ok') {

        setTimeout(() => {
          notification.success({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
          const updatedData = [...allmember];
          const updatedMember = {
            ...updatedData[index],
            groups: response.data.groups,
          };
          updatedData[index] = updatedMember;
          setAllMember(updatedData);
         }, 1000);

        }else{
          notification.error({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
        }
      }).catch((error) => {
        
      });
  };
  const rangePresets = [
    {
      label: 'Add 3 Days',
      value: [dayjs().add(+3, 'd'), dayjs()],
    },
    {
      label: 'Add 7 Days',
      value: [dayjs().add(+7, 'd'), dayjs()],
    },
    {
      label: 'Add 15 Days',
      value: [dayjs().add(+15, 'd'), dayjs()],
    },
    {
      label: 'Add 20 Days',
      value: [dayjs().add(+20, 'd'), dayjs()],
    },
    {
      label: 'Add 25 Days',
      value: [dayjs().add(+25, 'd'), dayjs()],
    },
    {
      label: 'Add 30 Days',
      value: [dayjs().add(+30, 'd'), dayjs()],
    },
    {
      label: 'Add 40 Days',
      value: [dayjs().add(+40, 'd'), dayjs()],
    },
    {
      label: 'Add 60 Days',
      value: [dayjs().add(+60, 'd'), dayjs()],
    },
    {
      label: 'Add 90 Days',
      value: [dayjs().add(+90, 'd'), dayjs()],
    },
  ];
  const onChangeban = (checked ,SteamEdit ,index) => {
  // console.log(index);
    Axios.put(`${API_URL}/api/updateban/${SteamEdit}/${checked}`).then((response) => {
      if (response.data.status === 'ok') {
        setTimeout(() => {
          notification.success({
            message: response.data.messages,
            duration: 3,
          });

          EditsetBan(response.data.Ban);
          const updatedData = [...allmember];
          const updatedMember = {
            ...updatedData[index],
            ban: response.data.Ban,
          };
          updatedData[index] = updatedMember;
          setAllMember(updatedData);
         }, 500);

        }else{
          notification.error({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
        }
      }).catch((error) => {
        
      });
    
  };

  const handleEditProfile = (index) => {
    const SteamID = allmember[index].username;

    // console.log(SteamID+' '+ NameEdit +' '+ PlayerIDEdit+' ' + X2Edit);
    const DataEdit = {
      SteamID: SteamID,
      Name:NameEdit,
      PlayerID:PlayerIDEdit,
      X2:X2Edit,
    };
    Axios.put(`${API_URL}/api/editmember`,DataEdit).then((response) => {
      if (response.data.status === 'ok') {

        setTimeout(() => {
          notification.success({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
          const DataEdit = [...allmember];
          const updatedMember = {
            ...DataEdit[index],
            name: response.data.DataEdit.Name,
            pyid: response.data.DataEdit.PlayerID,
            x2: response.data.DataEdit.X2,
          };
          DataEdit[index] = updatedMember;
          setAllMember(DataEdit);
         }, 1000);

        }else{
          notification.error({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setLoading(false);
        }
      }).catch((error) => {
        
      });


  }
  const handleNameChange = (event) => {
    const value = event.target.value;
    EditsetName(value);
  };
  
  const handlePlayerIDChange = (event) => {
    const value = event.target.value;
    EditsetPlayerID(value);
  };
  const handleX2Change = (event) => {
    const value = event.target.value;
    EditsetX2(value);
  };

  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: 'auto', width: '80%', margin: '0 auto', marginTop: '100px' }}>
      <Layout style={{ backgroundColor: 'transparent', cursor: 'pointer' }}>
            <Header style={{ ...headerStyle, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Breadcrumb
            items={[
              {
                href: '/Admin/Dashboard',
                title: <HomeOutlined />,
              },
              {
                title: (
                  <>
                    <span>Member List</span>
                  </>
                ),
              },
            ]}
          />
        </div>
        <Logout/>
      </Header>

        <Content style={{ ...contentStyle }}>
        <Input.Search
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginTop: 50 , width: 400 }}
          />
          <Table
            rowKey={(record) => record.key}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => expandedRowRender(record),
              expandRowByClick: false, // ปิดความสามารถในการคลิกที่แถวเพื่อเปิดรายละเอียด
              expandIcon: ({ expanded, onExpand, record }) => (
                <a onClick={() => handleRowClick(record)}>
                  {expanded ? <MinusOutlined /> : <PlusOutlined />}
                </a>
              ),
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                const updatedData = allmember.map((item, index) => {
                  if (index === parseInt(record.key)) {
                    return { ...item, isExpanded: expanded };
                  } else {
                    return { ...item, isExpanded: false };
                  }
                });
                setAllMember(updatedData);
              },
            }}
            dataSource={filteredData}
            pagination={{
              pageSize: 10, // จำนวนแถวต่อหน้า
              showSizeChanger: false, // แสดงตัวเลือกเปลี่ยนจำนวนแถวต่อหน้า
              pageSizeOptions: ['10', '20', '30'], // ตัวเลือกขนาดหน้า
              showTotal: (total) => `Total ${total} member`, // แสดงจำนวนรายการทั้งหมด
            }}
          />


<Modal title={`SteamID/EpicID ${SteamEdit}`}  open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
 footer={[
  <Popconfirm
  key="popconfirm"
  title="Are you sure you want to Edit this item?"
  onConfirm={() => handleEdit(currentIndex) }
  okText="Yes"
  cancelText="No"
>
  <Button type="primary" htmlType="submit">Edit</Button>
</Popconfirm>
    ]}>
  <Form
    form={form}
    name="basic"
    style={{
      maxWidth: 600,
    }}
    autoComplete="off"
  >
     <Form.Item
      label="ResetPassword"
      name="resetpassword"
      rules={[
        {
          required: false,
          message: 'Please input your password!',
        },
      ]}
      style={{ marginTop: '40px' }}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
    </Form.Item>
  </Form>
</Modal>


<Modal
  title={`SteamID/EpicID ${SteamEdit}`}
  open={isModalOpenGroups}
  onOk={handleOk}
  onCancel={handleCancel}
  footer={[
    <Popconfirm
      key="popconfirm"
      title="Are you sure you want to Edit this item?"
      onConfirm={() => handleEditGroups(currentIndexGruops)}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" htmlType="submit">AddGroups</Button>
    </Popconfirm>
  ]}
>
  <Select
    style={{ width: 150 }}
    onChange={handleChange}
    options={options}
  />

  <RangePicker
    value={selectedRange}
    onChange={handleRangeChange}
    renderExtraFooter={() => (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {rangePresets.map((preset) => (
          <Button
            key={preset.label}
            onClick={() => handlePresetClick(preset.value)}
            type={selectedRange === preset.value ? 'primary' : 'default'}
            style={{ margin: '5px' }}
          >
            {preset.label}
          </Button>
        ))}
      </div>
    )}
  />
</Modal>


<Modal
  title={`SteamID/EpicID ${SteamEdit}`}
  open={isModalDeleteGroups}
  onOk={handleOk}
  onCancel={handleCancel}
  footer={[
    <Popconfirm
      key="popconfirm"
      title="Are you sure you want to Edit this item?"
      onConfirm={() => handleDeleteGroups(currentIndexDeleteGruops)}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" htmlType="submit">DelGroups</Button>
    </Popconfirm>
  ]}
>
  <Select
    style={{ width: 150 }}
    onChange={handleChange}
    options={options}
  />

</Modal>

<Modal
    title={`SteamID/EpicID ${SteamEdit}`}
    open={isModalProfile}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={[
      <Popconfirm
        key="popconfirm"
        title="Are you sure you want to Edit this item?"
        onConfirm={() => handleEditProfile(currentIndexProfile)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" htmlType="submit">EditProfile</Button>
      </Popconfirm>
    ]}
  >
  <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
    <div style={{ display: 'flex' }}>
      <div style={{ width: 200 }}>
        <Form.Item label="Name" >
          <Input 
          type="text"
          id="Name"
          value={NameEdit}
          placeholder={NameEdit}
          onChange={handleNameChange}
          />

        </Form.Item>
      </div>
      <div style={{ width: 150, marginLeft: 10 }}>
        <Form.Item label="PlayerID">
        <Input 
          type="text"
          id="PlayerID"
          value={PlayerIDEdit}
          // placeholder={NameEdit}
          onChange={handlePlayerIDChange}
          />
        </Form.Item>
      </div>
      <div style={{ width: 50, marginLeft: 10 }}>
        <Form.Item label="X2">
          <Input 
          type="number"
          id="X2"
          value={X2Edit}
          // placeholder={X2Edit}
          onChange={handleX2Change}
          />
        </Form.Item>
      </div>
    </div>
    <div style={{ display: 'flex' }}>
    <div style={{ width: 150, marginLeft: 10 }}>
        <Form.Item label="Date">
          <DatePicker 
          showTime={false} 
          format="DD/MM/YYYY"
          id="Date" 
          disabled
          value={moment(DateEdit)}
          />
        </Form.Item>
      </div>
    <div style={{ width: 50, marginLeft: 10 }}>
      <Form.Item label="Ban">
        <Switch 
          checked={BanEdit === "true"}
        id="Ban"
        onChange={(checked) => onChangeban(checked, SteamEdit ,currentIndexProfile)} />
      </Form.Item>
    </div>
    </div>
  </Form>
</Modal>

        </Content>
      </Layout>
    </div>
  );
}

export default DashboardMember;
