
import React, { useState, useEffect } from 'react';
import { useNavigate   } from 'react-router-dom';
import Axios from "axios";
import config from '../../../config.json';
import {Button, Result ,message} from 'antd';
import { 
    SmileOutlined
} from '@ant-design/icons';
function CheckAdmin({loading}) {
    const navigate = useNavigate();
    const [Datauser, setDatauser] = useState([]);
    const [loadingpage, setLoadingpage] = useState(true);
    const API_URL = config.APP_API_URL;
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const apiKey = config.apiKey;
        const encodedApiKey = btoa(apiKey);
        const tokens = localStorage.getItem("Token");
        const headers = {
        "Content-Type": 'application/json',
        "Authoriztion": `${tokens}`,
        "X-API-Key": encodedApiKey,
        "X-Client-Domain": window.location.origin
      }

      const fetchLoginToken = async () => {
        if (tokens !== null) {
          try {
            const response = await Axios.post(`${API_URL}/api/login/authen`, {}, { headers });
    
            if (response.data.status === 'ok') {
              setDatauser(response.data.users[0]);
              if (response.data.users[0].userlevel === 'A') {
                setLoadingpage(false);
              }else{
                setLoadingpage(true);
                messageApi.open({
                    type: 'error',

                    content: 'คุณไม่มีสิทธ์เข้าถึงหน้าเว็บนี้ได้'
                  });
              }
            } else {
                setLoadingpage(true);
                messageApi.open({
                    type: 'error',
                    content: `${response.data.message}`
                  });
            }
          } catch (error) {
            setLoadingpage(true);
            // จัดการข้อผิดพลาด
          }
        } else {
            setLoadingpage(true);
          // window.location.href = '/homepage';
        }
      };
    
        fetchLoginToken();

      

    }, [loading]);

    
    const Backloging = async () => {
        localStorage.removeItem('Token');
        localStorage.removeItem('Login');
        localStorage.removeItem('isPopupClosed');
        navigate('/');
      };


      const Nextpage = async () => {
        navigate('/Admin/Dashboard');
      };
    return (
    <div>
         {contextHolder}
                {loadingpage ? (
                         <Result
                         status="403"
                         title={<span style={{ backgroundColor: 'yellow' }}>This ID is not authorized to access !</span>}
                         extra={
                           <Button type="primary" onClick={Backloging}>
                             Homepage
                           </Button>
                         }
                       />
                    ) : (
                        <>
                        <Result
                            icon={<SmileOutlined />}
                            title={<span style={{ backgroundColor: 'black'  , color : '#fff'}}>This ID is authorized to access.</span>}
                            extra={<Button type="primary" onClick={Nextpage}>Next</Button>}
                        />
                        </>
                    )}
  </div>
);
    // );
  }
  
  export default CheckAdmin;
  