import React, { useState, useEffect } from 'react';
import CheckAdmin from '../component/CheckAdmin/Checkadmin';

function Dashboard() {
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <CheckAdmin loading={loading}/>
        </>
      )}
    </div>
  );
}

export default Dashboard;
