import React, { useState ,useEffect } from 'react';
import config from '../../../config.json';
import Axios from "axios";
// import { useParams } from 'react-router-dom';
import {  Badge, Dropdown, Space, Table ,Image ,Layout,Upload ,Statistic,List,Col, Row ,Pagination ,Button as BTN ,notification ,Modal as AntdModal ,Form ,Popconfirm ,Input} from 'antd';
import { 
  UploadOutlined,DownOutlined,
} from '@ant-design/icons';
import Hispayment from './Hispayment';
import styled from 'styled-components';

const ScrollableContent = styled.div`
  overflow-y: auto; 
  height: 100%;
`;
const { Countdown } = Statistic;

const { Header, Content } = Layout;

export default function DataProfile({Datauser}) {
    const API_URL = config.APP_API_URL;
    const apiKey = config.apiKey;
    const encodedApiKey = btoa(apiKey);
    const tokens = localStorage.getItem("Token");
    const headers = {
      "Authoriztion": `${tokens}`,
      "X-API-Key": encodedApiKey,
      "X-Client-Domain": window.location.origin
    };
    const [Datausers , setDatauser] = useState([]);
    const [isModalProfile, setIsModalProfile] = useState(false);
    const [isModalOpenRepass, setisModalOpenRepass] = useState(false);
    const [form] = Form.useForm();
    const [NameEdit, EditsetName] = useState('');
    useEffect(() => { 
        setDatauser(Datauser);
        EditsetName(Datauser.name);

        // console.log(Datausers);
    }, [Datauser]);


    const showProfile = (SteamID) => {
          setIsModalProfile(true);
    };
    const showRepass = () => {
      setisModalOpenRepass(true);
};

    const handleCancel = () => {
      setIsModalProfile(false);
      setisModalOpenRepass(false);
    };

    
    const handleEditProfile = (SteamID) => {
      const DataEdit = {
        SteamID: SteamID,
        Name:NameEdit,
      };
      Axios.put(`${API_URL}/api/usereditmember`,DataEdit,{headers}).then((response) => {
        if (response.data.status === 'ok') {
          notification.success({
            message: response.data.messages,
            duration: 3,
            description: response.data.messagesen,
          });
          setTimeout(() => {
            window.location.reload();
           }, 3000);
  
          }else{
            notification.error({
              message: response.data.messages,
              duration: 3,
              description: response.data.messagesen,
            });
          }
        }).catch((error) => {
        console.error(error);
        });
    };

    const handleRepass = (id) => {
      const resetPassword = form.getFieldValue('resetpassword');
      const updatedData = {
        resetpassword: resetPassword, // ค่าที่ต้องการอัปเดต
        // รายละเอียดข้อมูลอื่น ๆ ที่ต้องการอัปเดต
      };
      Axios.put(`${API_URL}/api/repassmember/${id}`, updatedData,{headers}).then((response) => {
        if (response.data.status === 'ok') {
          setTimeout(() => {
            notification.success({
              message: response.data.messages,
              duration: 3,
              description: response.data.messagesen,
            });
            setisModalOpenRepass(false);
           }, 1000);
        }else{
          notification.error({
            message: response.data.messages,
            duration: 3,
            description: response.data.messages,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
      }


    const items = [
      {
        key: '0',
        label: 'EditProfile',
      },
      {
        key: '1',
        label: 'Repass',
      },
    ];
    const expandedRowRender = () => {
        const columns = [
            {
                title: 'Groups',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Timeout',
                dataIndex: 'date',
                key: 'date',
              },
              {
                title: 'Countdown',
                dataIndex: 'endDate',
                key: 'countdown',
                render: (endDate) => {
                  const now = new Date();
                  const formattedEndDate = new Date(endDate).toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  });
        
                  return (
                    <Countdown
                      value={endDate}
                      format="D วัน H ชั่วโมง m นาที s วิ"
                      suffix={now > endDate ? ' (หมดอายุ)' : ''}
                    />
                  );
                },
              },
              {
                title: 'Status',
                key: 'state',
                render: (_, record) => {
                  const now = new Date();
                  const endDate = new Date(record.endDate);
                  const isFinished = endDate > now;
        
                  return isFinished ? (
                    <Badge status="success" text="Not expired" />
                  ) : (
                    <Badge status="error" text="Expired" />
                  );
                },
              },
            ];
            const groups = Datausers.groups ? Datausers.groups.split(',') : [];
            const groupData = groups.map((group, index) => {
              const [name, startDate, endDate] = group.replace(/\[|\]/g, '').split('|');
        
              const formattedStartDate = new Date(startDate).toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              });
        
              const formattedEndDate = new Date(endDate).toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              });
        
              return {
                key: '0',
                name,
                date: `${formattedStartDate} - ${formattedEndDate}`,
                endDate: new Date(endDate),
              };
            });
        
            return <Table columns={columns} dataSource={groupData} pagination={false} />;
          };
    const columns = [
        {
          title: 'IMG',
          dataIndex: 'p_img',
          key: 'p_img',
          width: 250,
          render: (text, record) => (
            <>
             <Space>
              <Image src={`${API_URL}/api/images/profile/${text}`} alt="User Image" style={{ width: 50, height: 50 }} />

             <Upload 
             action={`${API_URL}/api/uploadpic/${record.SteamID}`}
             // listType="picture"
             maxCount={1}
             name="image"
             beforeUpload={(file) => {
              const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
              if (allowedTypes.includes(file.type)) {
                return true;
              } else if(file.status === 'error'){
                  notification.error({
                  message: 'Invalid file type. Please upload a jpeg, png, gif, or webp image.',
                  duration: 3,
                  // description: response.data.messagesen,
                });
                
                return false;
                
                
              }
            }}
            onChange={(info) => {
              if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                notification.success({
                  message: `${info.file.name} file uploaded successfully`,
                  duration: 2,
                  // description: response.data.messagesen,
                });
                setTimeout(() => {
                  window.location.reload();
                 }, 2000);
                // const response = info.file.response;
                // console.log(response);
  
                // message.success();
                // เมื่ออัปโหลดสำเร็จ ดำเนินการอื่น ๆ ที่ต้องการ
                // const { index } = info.file;
                // console.log(info.file.originFileObj)
                // ReloadRow(index ,response.IMG);
                // handleUpload(info.file.originFileObj, index);
              } else if (info.file.status === 'error') {
                notification.error({
                  message: `${info.file.name} file upload failed.(Please upload a jpeg, png, gif, or webp image.)`,
                  duration: 3,
                  // description: response.data.messagesen,
                });
              }
            }}
             >
          <BTN icon={<UploadOutlined />}>Upload </BTN>
          </Upload> 
          </Space>
            </>
          ),
        },
        {
          title: 'SteamID/EpicID',
          dataIndex: 'SteamID',
          key: 'SteamID',
          render: (text, record) => (
            <span style={{ backgroundColor: record.ban === 'true' ? 'red' : 'transparent' }}>{text}</span>
          ),
        },
        {
          title: () => (
            <>
              {/* <Button onClick={showModal}>Re-Name</Button> */}
              {/* <EditProfile/> */}
              Name
            </>
          ),
          dataIndex: 'name',
          key: 'name',
          render: (text, record) => (
            <>
            <Space>
        
            {text}
            </Space>
          
            </>),
        },
        {
          title: 'Groups',
          dataIndex: 'groups',
          key: 'groups',
        },
        {
          title: 'X2',
          dataIndex: 'x2',
          key: 'x2',
        },
        {
          title: 'Edit',
          key: 'operation',
          render: (_, record, index) => {
            const menuProps = {
              items,
              onClick: (e) => handleMenuClick(e, index),
            };
        
            const handleMenuClick = (e, index) => {
              // console.log('idmember', record.idmember);
              // console.log('key', record.key);
              if (e.key === '0') {
                // console.log('0')
                showProfile(record.SteamID);
              }
              if (e.key === '1') {
                // console.log('1')
                showRepass(record.SteamID);
              }
    
            };
            // const confirmDelete = (index) => {
            //   Modal.confirm({
            //     title: 'Delete the task',
            //     content: 'Are you sure to delete this task?',
            //     okText: 'Yes',
            //     cancelText: 'No',
            //     // onOk: () => handleDelete(index),
            //   });
            // };
            
            return (
              <Dropdown menu={menuProps}>
                <BTN>
                  <Space>
                    <DownOutlined />
                  </Space>
                </BTN>
              </Dropdown>
            );
          },
        }

      ];
      
      const row = {
        key: '0',
        SteamID: Datausers.username,
        name: Datausers.name,
        email: Datausers.email,
        groups : Datauser.PermissionGroups,
        // date_save: new Date(Datausers.date_save).toLocaleDateString('en-GB'),
        p_img: Datausers.p_img,
        x2: Datausers.x2,
      };


      const datarateonline = [
        {Groups :'Default' ,Points :'2 Points'},
        {Groups :'LegendPlayer',Points :'4 Points'},
        {Groups :'VoteFN[TH]',Points :'5 Points'},
        {Groups :'30Day',Points :'5 Points'},
        {Groups :'ProPlayer',Points :'6 Points'},
        {Groups :'TopOnline',Points :'6 Points'},
        {Groups :'VIP' ,Points :'8 Points'},
        {Groups :'LV1',Points :'8 Points'},
        {Groups :'LV2',Points :'10 Points'},
        {Groups :'LV3',Points :'12 Points'},
        {Groups :'SVIP',Points :'13 Points'},
        {Groups :'LV4',Points :'15 Points'},
        {Groups :'LV5',Points :'20 Points'},
        {Groups :'Alpha',Points :'20 Points'},
      ];


      const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalItems = datarateonline.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const slicedData = datarateonline.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNameChange = (event) => {
    const value = event.target.value;
    EditsetName(value);
  };

    return(
      <>
<div  style={{
  overflowY: 'scroll',
  overflowX: 'scroll',
  maxHeight: 'calc(100vh - 90px)',
}}>

{/* <ScrollableContent> */}

<Content>
<Row className="justify-content-center">
  <Col span={6} className="mb-4" style={{marginRight:'10px'}}>
  <List
        size="small"
        bordered
        header={<div style={{ display: 'flex', justifyContent: 'center' }}><b>Online group / 30 min</b></div>}
        footer={<div style={{ display: 'flex', justifyContent: 'center' }}>can have multiple groups (Default+30Day = 7p /30min)</div>}
        dataSource={slicedData}
        renderItem={(item) => (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{item.Groups}</span>
            <span>{item.Points}</span>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={totalItems}
        onChange={handleChangePage}
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      />
  </Col>
 
</Row>
</Content>

<Content>

 <h3>Profile</h3>
<Table
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ['0'],
        }}

        dataSource={[row]}
        pagination={false} 
      />
</Content>




<AntdModal

    title={`SteamID/EpicID ${Datausers.username}`}
    open={isModalProfile}
    getContainer={false}
    // onOk={handleOk}
    onCancel={handleCancel}
    footer={[
      <Popconfirm
        key="popconfirm"
        title="Are you sure you want to Edit this item?"
        onConfirm={() => handleEditProfile(Datausers.username)}
        okText="Yes"
        cancelText="No"
      >
        <BTN type="primary" htmlType="submit">EditProfile</BTN>
      </Popconfirm>
    ]}
  >
<div style={{ width: 200 }}>
        <Form.Item label="Name" >
          <Input 
          type="text"
          id="Name"
          value={NameEdit}
          placeholder={NameEdit}
          onChange={handleNameChange}
          />

        </Form.Item>
      </div>
  </AntdModal>


  <AntdModal title={`SteamID/EpicID ${Datausers.username}`} getContainer={false} open={isModalOpenRepass} onCancel={handleCancel}
 footer={[
  <Popconfirm
  key="popconfirm"
  title="Are you sure you want to Edit this item?"
  onConfirm={() => handleRepass(Datausers.id) }
  okText="Yes"
  cancelText="No"
>
  <BTN type="primary" htmlType="submit">Edit</BTN>
</Popconfirm>
    ]}>
  <Form
    form={form}
    name="basic"
    style={{
      maxWidth: 600,
    }}
    autoComplete="off"
  >
     <Form.Item
      label="ResetPassword"
      name="resetpassword"
      rules={[
        {
          required: false,
          message: 'Please input your password!',
        },
      ]}
      style={{ marginTop: '40px' }}
    >
      <Input.Password />
    </Form.Item>

  </Form>
</AntdModal>


 <br/><br/>
 <h3>Hispayment</h3>
    <Content>
      <Hispayment API={API_URL} headers={headers} setDatauser={setDatauser}/>
      </Content>
      {/* </ScrollableContent> */}
 </div>
 </>
    );

    

 
    
}