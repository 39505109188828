import './App.css';
import LicenseKey from './component/License/License';
import NavbarTop from './component/Navbar/NavbarTop';
import Header from './component/Content/Header';

function App() {
  

  return (
    <div className="App">
      {/* <LicenseKey></LicenseKey> */}
     <NavbarTop></NavbarTop>
     <Header></Header>
    </div>
  );
}

export default App;
