import React, { useState, useEffect } from "react";
import {  Badge, Dropdown, Space, Table ,Image ,Layout,Upload ,Statistic,Tag,Col, Row ,Pagination ,Button as BTN ,notification ,Modal as AntdModal ,Form ,Popconfirm ,Input} from 'antd';
import { 
  CheckCircleOutlined,SyncOutlined,CloseCircleOutlined
} from '@ant-design/icons';
import Axios from "axios";
import Fixpage from "../FIXPAGE/Fixpage";

const { Content } = Layout;

export default function Home() { 

    return(
<Content>
      <Row
        className="justify-content-center"
        style={{
          marginTop: '10vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden' // ปรับปรุงที่นี่ เป็น String แทนเครื่องหมาย ;
        }}
      >
        <iframe
          src="https://discord.com/widget?id=626846835331301391&theme=dark"
          width="550"
          height="600"
          allowtransparency="true"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
      </Row>
      <Fixpage/>
    </Content>

    );
}