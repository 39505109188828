import React, { useState ,useEffect ,useRef} from 'react';
import { Radio, Badge, Space, Steps, Image, Layout, Descriptions, Statistic, Result, Col, Row, Pagination, Button as BTN, notification, Modal as AntdModal, Form, Popconfirm, Input, Button } from 'antd';
import { 
    DollarOutlined,
    SmileOutlined,
    CheckCircleOutlined,
    LoadingOutlined,
    LeftOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import Axios from "axios";
import config from '../../../../config.json';
import QRCode from 'qrcode';
import CountUp from 'react-countup';
import { useUserContext } from '../../../Navbar/Context';

const { Content, Footer, Sider } = Layout;




export default function PayQRS1({API,headers,Datapack,StepsHome,setStepsHome ,setIsModalTopup ,setActiveRadio ,setActiveButton ,setPackCode ,settagcode}) {
    const { setPoints } = useUserContext();
    const formatter = (value) => <CountUp end={value} separator="," />;
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [price, setprice] = useState(0);
    const [referenceNo, setReferenceNo] = useState('');
    const [imageData, setImageData] = useState('');
    const [icon, seticon] = useState(<Loading3QuartersOutlined />);
    // console.log(Datapack)
    const [statuspay, setstatuspay] = useState('process');
    const [statussucceed, setstatussucceed] = useState('wait');
    const [refsucceed, setrefsucceed] = useState('');
    const canvasRef = useRef(null);
    const Detail = Datapack.Detail[0];
    const Packcode = Datapack.PackCode;
    const showPopconfirm = () => {
      setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setStepsHome(1);
            setImageData('');
            setReferenceNo('');
            setstatuspay('process');
            setstatussucceed('wait');
            seticon(<Loading3QuartersOutlined />);
            setIsModalTopup(false);
            setActiveRadio(null);
            setActiveButton(null);
            setPackCode([]);
            settagcode([]);
        //     setIsModalPayment(false);
        //     setPaymentStatus(false);
          }, 200);
      };
      const handleBuyagain = () => {
        setOpen(false);
        setTimeout(() => {
            setStepsHome(1);
            setImageData('');
            setReferenceNo('');
            setstatuspay('process');
            setstatussucceed('wait');
            seticon(<Loading3QuartersOutlined />);
            setActiveRadio(null);
            setActiveButton(null);
            setPackCode([]);
            settagcode([]);
        //     setIsModalPayment(false);
        //     setPaymentStatus(false);
          }, 200);
      };
      const handleback = () => {
      
        setOpen(false);
         setTimeout(() => {
            setStepsHome(1);
        // setIsModalPayment(false);
            setReferenceNo('');
            setPackCode([]);
            settagcode([]);
            setImageData('');
        //     setIsModalOpen(true);
          }, 200);
        
        
      };

      const handleOkPopconfirm = async () => {
        setConfirmLoading(true);
        // 
        try {
            
            const response = await Axios.post(`${API}/api/topupqr`,{Datapack},{headers});
            // setUserID(DataTopup.Datauser.Userid);
            if(response.data.status === 'error'){
              setOpen(false);
              setConfirmLoading(false);
              notification.warning({
                message: `${response.data.message}`,
              });
            }else{
              setprice(response.data.priceNumber);
              // console.log(response.data);
              setTimeout(() => {
                setReferenceNo(response.data.resdata.referenceNo)
                setImageData(response.data.resdata.qrcode);
                setOpen(false);
                seticon(<LoadingOutlined/>);
              //   setStepsHome(StepsHome+1);
                setConfirmLoading(false);
              }, 1000);
            }
            
        } catch (err) {
          console.log(err);
        }
    
       
      };


      useEffect(() => {
        const generateQRCode = async () => {
          if (imageData && canvasRef.current) { 
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
    
            // Clear the previous image
            context.clearRect(0, 0, canvas.width, canvas.height);
    
            try {
              await QRCode.toCanvas(canvas, imageData, { width: 200 });
            } catch (error) {
              console.error("Error generating QR Code:", error);
            }
          }
        };
    
        generateQRCode();
      }, [imageData]);

   

      const fetchDataFromDB = async (API, refNo, headers) => {

        try {
          const response = await Axios.get(API+'/api/checkreferenceNo',{
            params: {
              refNo: refNo,
            },
            headers: headers,
          });
          setrefsucceed(refNo);
          
          if(response.data.status === 'success'){
            setReferenceNo('');
            seticon(<CheckCircleOutlined/>);
            setImageData('');
            settagcode([]);
            setPackCode([]);
            setstatuspay('finish');
            setstatussucceed('process');

            const response = await Axios.post(`${API}/api/login/authen`, {}, { headers });
            // console.log(response.data.users[0].Points);
            setPoints(response.data.users[0].Points);
          }
        } catch (error) {
          console.error('เกิดข้อผิดพลาดในการดึงข้อมูล:หรือยังไม่ได้รับการชำระเงิน');
        }
      };
      useEffect(() => {
        if (referenceNo !== '') {
          // หาก referenceNo ไม่เป็นค่าว่าง ให้เริ่ม interval และเรียก fetchDataFromDB
          const interval = setInterval(() => {
            fetchDataFromDB(API ,referenceNo ,headers);
          }, 3000);
    
          // ให้คืนค่าฟังก์ชันที่เป็นการทำความสะอาด
          return () => {
            clearInterval(interval);
          };
        }
      }, [API, referenceNo ,headers]);

return(
<>
<Content style={{marginBottom:'20px'}}>

<Steps
    items={[
      {
        title: 'Choose a package',
        status: 'finish',
        icon: <CheckCircleOutlined />,
      },
      {
        title: 'Pay',
        status: statuspay,
        icon:  icon,
      },
      {
        title: 'Done',
        status: statussucceed,
        icon: <SmileOutlined />,
      },
    ]}
  />
  
</Content>

  
  { statussucceed === 'wait' ? 
  <>
  <Content style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>


{imageData ? null : ( 
  <>
  
  
  {Detail.pk_name === 'Rank' ? 
  <> 
   
  <Descriptions title="Detail">
  <Descriptions.Item label="Packages">{Detail.pk_name}</Descriptions.Item>
  <Descriptions.Item label="NamePack">{Detail.tu_name === '30Day' ? <>{Detail.tu_name} {Detail.tu_timeday} </> : <>{Detail.tu_name} {Detail.tu_timeday} Day </>} </Descriptions.Item>
  <Descriptions.Item label="Amount">{Detail.tu_price} บ./{Detail.tu_priceUS} usd </Descriptions.Item>
  <Descriptions.Item label="Free">  {Detail.tu_addtokens !== null ? <>{Detail.tu_addtokens}  Tokens</> : <>None </>} </Descriptions.Item>
  <Descriptions.Item label="Discount">
  {Packcode.Type === '%' ? (
                              <>
                                {Packcode.Dis} %
                              </>
                            ) : Packcode.Type === 'baht' ? (
                              <>{(Packcode.Dis).toFixed(2)} บาท / {Packcode.DisUS} USD</>
                            ) : (
                              <>0.00 บาท / 0.00 USD</>
                            )}
     </Descriptions.Item>
  <Descriptions.Item label="Total">
  {Packcode.Type === '%' ? (
                              <>
                                {(Detail.tu_price - (Detail.tu_price * (Packcode.Dis / 100))).toFixed(2)} บาท
                                / {(Detail.tu_priceUS - (Detail.tu_priceUS * (Packcode.Dis / 100))).toFixed(2)} USD
                              </>
                            ) : Packcode.Type === 'baht' ? (
                              <>{(Detail.tu_price - Packcode.Dis).toFixed(2)} บาท / {(Detail.tu_priceUS - Packcode.DisUS).toFixed(2)} USD</>
                            ) : (
                              <>{Detail.tu_price} บาท  / {Detail.tu_priceUS} USD </>
                            )}
  </Descriptions.Item>
  </Descriptions>
  </> : <> 
  <Descriptions title="Detail">
  <Descriptions.Item label="Packages">{Packcode.Type === 'X' ? <>{Detail.pk_name}*{Packcode.Dis} </> : <>{Detail.pk_name}</>}</Descriptions.Item>
  <Descriptions.Item label="NamePack">{Packcode.Type === 'X' ? <>{Detail.tu_addpoints}*2 = {Detail.tu_addpoints * Packcode.Dis} Points</> : <>{Detail.tu_name}</>}</Descriptions.Item>
  <Descriptions.Item label="Amount">{Detail.tu_price} บ./{Detail.tu_priceUS} usd </Descriptions.Item>
  <Descriptions.Item label="Free">  {Detail.tu_addrank !== null ? <>{Detail.tu_addrank} {Detail.tu_timeday} Day</> : <>None</>} </Descriptions.Item>
  <Descriptions.Item label="Discount">
  {Packcode.Type === '%' ? (
                              <>
                                {Packcode.Dis} %
                              </>
                            ) : Packcode.Type === 'baht' ? (
                              <>{(Packcode.Dis).toFixed(2)} บาท / {Packcode.DisUS} USD</>
                            ) : (
                              <>0.00 บาท / 0.00 USD</>
                            )}
     </Descriptions.Item>
  <Descriptions.Item label="Total">
  {Packcode.Type === '%' ? (
                              <>
                                {(Detail.tu_price - (Detail.tu_price * (Packcode.Dis / 100))).toFixed(2)} บาท
                                / {(Detail.tu_priceUS - (Detail.tu_priceUS * (Packcode.Dis / 100))).toFixed(2)} USD
                              </>
                            ) : Packcode.Type === 'baht' ? (
                              <>{(Detail.tu_price - Packcode.Dis).toFixed(2)} บาท / {(Detail.tu_priceUS - Packcode.DisUS).toFixed(2)} USD</>
                            ) : (
                              <>{Detail.tu_price} บาท  / {Detail.tu_priceUS} USD </>
                            )}
  </Descriptions.Item>

  </Descriptions>
  </>}

  
                

  <Popconfirm
    placement="right"
    title="ยืนยันการชำระเงิน"
    description="Are you sure you want to pay?"
    open={open}
    onConfirm={handleOkPopconfirm}
    cancelText={null}
    okButtonProps={{
      loading: confirmLoading,
    }}
    afterClose={handleClose}
    onCancel={handleback}
  >
 
    <Button type="primary" onClick={showPopconfirm}>
      Next
    </Button>
 
  </Popconfirm>
  </>
 )}
  {imageData && 
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    
            <>
            <canvas ref={canvasRef} style={{ marginBottom: '10px' }} />
            <span style={{ fontSize: '24px', fontWeight: 'bold', color: '#1890ff' }}>{price} THB</span>
            </>
      
    </div>
  }

</Content>
<Button onClick={handleback} danger style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <LeftOutlined /> Back
</Button>

  </> 
  : 
  <>
  <Content style={{ display: 'grid', placeItems: 'center', height: '30vh' }}>
  <Result
    status="success"
    title="Successfully Purchased!"
    subTitle={`Order number: ${refsucceed}`}
    extra={[
      <BTN onClick={handleClose}>
        Close
      </BTN>,
      <BTN  onClick={handleBuyagain}>
      Buy Again
    </BTN>,
    ]}
  />
  </Content>
  </>}



</>
);

}