// import '../../App.css';
// import '../homepage/homepage.css';
// import LicenseKey from '../License/License';
// import { useParams } from 'react-router-dom';
import NavbarTop from '../Navbar/NavbarTop';
// import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
function ShopFN() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
// console.log(IDSHOP);
    return (
    <div>
    {loading ? (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    ) : (
<NavbarTop/>
    )}
  </div>
);
    // );
  }
  
  export default ShopFN;
  