import React, { useState ,useEffect } from 'react';
import NavbarTop from '../Navbar/NavbarTop';

export default function Topup() {

    const [loading, setLoading] = useState(false);
    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, []);
    return(
<>
<div>
    {loading ? (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    ) : (
      <>

<NavbarTop/>
</>

    )}
  </div>
 </>
    ); 
}