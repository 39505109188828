import React, { useState, useEffect, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Space, Tag, Tooltip, theme, Input ,notification} from "antd";
import Axios from "axios";
import config from '../../../config.json';
// import e from "cors";


export default function CodeCoupon({ setTagcode ,setPackCode ,headers  ,API }) {
  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleInputConfirm = async () => {
    if (inputValue && !tags.includes(inputValue)) {

      try{

        const response = await Axios.post(`${API}/api/codecoupon`,{code:inputValue},{headers});
        // console.log(response.data);
        // const rescode =response.data.packcode;
        
        if(response.data.status === 'success'){
          setPackCode(response.data.packcode);
          setTags([inputValue]);
          setTagcode([inputValue]);
          // console.log(rescode);
          notification.success({
            message: `${response.data.message}`,
          });
        }else if(response.data.status === 'warning'){
          setInputVisible(false);
          setInputValue("");
          setPackCode([]);
          notification.warning({
            message: `${response.data.message}`,
          });
        }else{
          setInputVisible(false);
          setInputValue("");
          setPackCode([]);
          notification.error({
            message: `${response.data.message}`,
          });
        }

      }catch(err){
        console.log(err);
      }

      
      // console.log(inputValue);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };

  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  return (
    <>
      <Space size={[0, 8]} wrap>
        {tags.map((tag, index) => {
          return (
            <Tag
              key={tag}
              color="gold"
              closable={index >= 0}
              style={{
                userSelect: "none",
              }}
            >
              {tag}
            </Tag>
          );
        })}
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={tagInputStyle}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag style={tagPlusStyle} onClick={() => setInputVisible(true)}>
            <PlusOutlined /> Code
          </Tag>
        )}
      </Space>
    </>
  );
}
