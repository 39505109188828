import config from '../../config.json'
import Axios from "axios";


// const API_KEY = '9eea7bb7a22802ee7f9fd8fcedae21ba';
const tokens = localStorage.getItem("Token");
const headers = {
"Authoriztion": `${tokens}`,
}

let Member = null;
// let readHistory = null;
let Groups = null;
// let Top10 = null;
const API = config.APP_API_URL;
export const AllMember = async () => {
  if (Member && Groups) {
    // ถ้ามีข้อมูลอยู่แล้ว ก็จะ return ค่า novelData เลย
    return [Member ,Groups];
  }
  try {
     const response = await Axios.post(`${API}/api/AllMember`,{},{headers});
    Member = response.data.Member;
    // readHistory = response.data.history;
    Groups = response.data.Groups;
    // Top10 = response.data.top10;
    return [Member ,Groups];
  } catch (error) {
     console.log("ไม่ผ่าน");
  }
};

let Item = null;
export const AllItem = async () => {
  if (Item) {
    return [Item];
  }
  try {
     const response = await Axios.post(`${API}/api/AllItem`,{},{headers});
     Item = response.data.Item;
    return [Item];
  } catch (error) {
     console.log("ไม่ผ่าน");
  }
};
