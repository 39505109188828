import React ,{ useState ,useEffect } from 'react';
import { Row, Col ,Breadcrumb ,Layout ,Card ,notification} from 'antd';
import BoxMember from './CardMember';
import BoxAllItem from './CardItemAll';
import { useNavigate   } from 'react-router-dom';
// import CheckDataUser from '../component/GetDataUser/GetDataUser';
import Logout from '../component/Logout/Logout';
import config from '../../config.json';
import Axios from "axios";
import { 
    HomeOutlined, UserOutlined
} from '@ant-design/icons';
const { Header, Footer, Sider, Content } = Layout;
const API_URL = config.APP_API_URL;
const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#fff',
    height: 45,
    paddingInline: 50,
    lineHeight: '45px',
    backgroundColor: '#fff',
    borderRadius: '10px',
  };
  const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginTop: '10px',
  };
function DashboardAdmin() {
  const navigate = useNavigate();

  const [Countmember, setCountmember] = useState(0);
  const [Countitem, setCountitem] = useState(0);
  useEffect(() => {
    const tokens = localStorage.getItem("Token");
        const headers = {
        "Authoriztion": `${tokens}`,
        }
    Axios.post(`${API_URL}/api/getdatadashboard`,{},{headers}).then((response) => {
      if (response.data.status === 'ok') {
          notification.success({
            message: response.data.messages,
            duration: 3,
          });
          setCountmember(response.data.totalmember[0].totalmember)
          setCountitem(response.data.totalitem[0].totalitem)
  
      }else{
        notification.error({
          message: response.data.messages,
          duration: 3,
        });
        // navigate('/Admin/Home');
      }
    })
    .catch((error) => {

      console.error(error);
    });

  }, []);




  return (
   
    <div style={{ display: 'flex', justifyContent: 'center', height: 'auto' ,width :'80%', margin: '0 auto' ,marginTop :'100px'}}>
            <Layout style={{ backgroundColor: 'transparent' ,cursor: 'pointer'}}>
            <Header style={{ ...headerStyle, display: 'flex', justifyContent: 'space-between' }}>
            <div>
            <Breadcrumb
    items={[
      {
        href: '/Admin/Dashboard',
        title: <HomeOutlined />,
      },

    ]}
  />
  {/* <CheckDataUser/> */}
   </div>
   <Logout/>
  </Header>
      <Content style={contentStyle}>
      <Card >
      <Row gutter={[16, 16]}>
        <Col span={6}>
        <BoxMember title="Box 1"  count={Countmember}/>
        </Col>
        <Col span={6}>
        <BoxAllItem title="Box 2" count={Countitem}/>
        </Col>
        <Col span={6}>
          
        </Col>
        <Col span={6}>
          
        </Col>
      </Row>
      </Card>
      </Content>
      </Layout>
    </div>
  );
}

export default DashboardAdmin;
